import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { t } from "i18next";
import {
  capitalizeFirstLetter,
  findOwner,
  formatDate,
  formatPipe,
  formatRelationship,
  formatTime,
} from "../../utils/functions";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { LgAbout } from "../forms/LgAbout";
import { LgPersonal } from "../forms/LgPersonal";
import { LgEducation } from "../forms/LgEducation";
import { LgOccupation } from "../forms/LgOccupation";
import { LgHealth } from "../forms/LgHealth";
import { LgPersonality } from "../forms/LgPersonality";
import { UserNameCard } from "../atoms/UserNameCard";
import { useNavigate } from "react-router-dom";
import { LgSpouse } from "../forms/LgSpouse";

export const LgAboutView = ({ data }) => {
  const fontStyle = {
    color: "#000000",
    fontSize: "16px",
    py: 2,
    px: 1,
    wordWrap: "break-word",
  };
  //-----------------------Declare Hooks Here-----------------------------//

  const { activeLgId } = useSelector((state) => state.profile);
  const { userStatus } = useSelector((state) => state.config);
  const [editPromt, setEditPromt] = useState(false);
  const [spouseData, setSpouseData] = useState(null);
  const navigate = useNavigate();
  const owner = findOwner(activeLgId);
  const showEditButton =
    (owner?.owner_type === "Self" ||
      owner?.owner_type === "Owner" ||
      owner?.owner_type === "Collab") &&
    userStatus === "active";
  const showEditButtonOnAbout =
    (owner?.owner_type === "Self" || owner?.owner_type === "Owner") &&
    userStatus === "active";
  const personal =
    data.output.birth_cntry ||
    data.output.dob ||
    data.output.race ||
    data.output.caste ||
    data.output.star ||
    data.output.clan ||
    data.output.native_language ||
    data.output.last_res ||
    data.output.dod ||
    data.output.death_cause ||
    data.output.mortal_remains_location ||
    data.output.pers_add_details
      ? true
      : false;
  const education =
    data.output.schools ||
    data.output.colleges ||
    data.output.edu_lvl ||
    data.output.edu_area ||
    data.output.edu_qual ||
    data.output.edu_details
      ? true
      : false;
  const occupation =
    data.output.main_occupation ||
    data.output.sec_occupation ||
    data.output.workplaces ||
    data.output.occu_details
      ? true
      : false;
  const health =
    data.output.blood_group ||
    data.output.smoking ||
    data.output.drinking ||
    data.output.chronic_illness ||
    data.output.physical_activity ||
    data.output.health_details
      ? true
      : false;
  const personality =
    data.output.best_qual ||
    data.output.worst_qual ||
    data.output.hobbies ||
    data.output.fav_food ||
    data.output.fav_sports ||
    data.output.fav_books ||
    data.output.fav_music ||
    data.output.persntly_add_details
      ? true
      : false;
  const var_is =
    data?.output?.deceased === "Yes"
      ? t("display.text.lgAbout.was")
      : t("display.text.lgAbout.is");
  const var_gender_pronoun_caps =
    data?.output?.gender === "Male"
      ? t("display.text.lgAbout.He")
      : t("display.text.lgAbout.She");
  const var_gender_pronoun =
    data?.output?.gender === "Male"
      ? t("display.text.lgAbout.he")
      : t("display.text.lgAbout.she");
  const var_gender_pronoun2_caps =
    data?.output?.gender === "Male"
      ? t("display.text.lgAbout.His")
      : t("display.text.lgAbout.Her");
  const var_gender_pronoun2 =
    data?.output?.gender === "Male"
      ? t("display.text.lgAbout.his")
      : t("display.text.lgAbout.her");
  const headingStyles = { fontSize: "20px", fontWeight: 400, color: "#000000" };
  const subHeadingStyles = {
    fontSize: "16px",
    fontWeight: 600,
    color: "#333333",
  };
  const valueStyles = {
    fontSize: "16px",
    fontWeight: 400,
    color: "#666666",
  };

  return (
    <Card sx={{ mt: 1 }}>
      <Stack spacing={1}>
        <Card sx={{ minHeight: "150px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
            }}
          >
            <Typography sx={{ flex: 1, ...headingStyles }}>
              {t("display.text.text.introduction")}
            </Typography>
            {showEditButtonOnAbout && (
              <IconButton onClick={() => setEditPromt("about")}>
                <EditIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )}
          </Box>
          <Divider sx={{ width: "100%" }} />
          <Grid container spacing={1.5} sx={{ p: 1 }}>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.first_name")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.first_name || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.last_name")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.last_name || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.nickname")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.nickname || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.gender")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.gender || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.lg_url")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.lg_url || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.deceased")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.deceased || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.basic_add_details")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.basic_add_details || "- -"}
              </Typography>
            </Grid>
          </Grid>
          {/* <Typography sx={fontStyle}>
            {capitalizeFirstLetter(data.output.first_name)}{" "}
            {data.output.first_name
              ? data.output.last_name +
                " was popularly known as" +
                " " +
                data.output.first_name
              : null}
          </Typography>
          {data.output.basic_add_details && (
            <Typography
              sx={{
                color: "#000000",
                fontSize: "16px",
                px: 1,
                pb: 2,
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
              }}
            >
              {data.output.basic_add_details}
            </Typography>
          )} */}
        </Card>
        <Card sx={{ minHeight: "150px" }}>
          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <Typography sx={{ flex: 1, ...headingStyles }}>
              {t("display.text.text.personal")}
            </Typography>
            {showEditButton && (
              <IconButton onClick={() => setEditPromt("personal")}>
                <EditIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )}
          </Box>
          <Divider sx={{ width: "100%" }} />
          {/* {personal ? (
            <>
              <Typography sx={fontStyle}>
                {data?.output?.birth_cntry &&
                  t("display.text.lgAbout.wasBornIn", {
                    first_name: data?.output?.first_name,
                    birth_cntry: data?.output?.birth_cntry,
                  })}
                {data?.output?.dob &&
                  t("display.text.lgAbout.wasBornOn", {
                    param1: var_gender_pronoun_caps,
                    dob: formatDate(data?.output?.dob),
                  })}
                {data?.output?.race &&
                  t("display.text.lgAbout.a", {
                    param1: var_gender_pronoun_caps,
                    param2: var_is,
                    race: data?.output?.race,
                  })}
                {(data?.output?.star ||
                  data?.output?.clan ||
                  data?.output?.caste) &&
                  t("display.text.lgAbout.wasBorn", {
                    param1: var_gender_pronoun_caps,
                  })}
                {data?.output?.star &&
                  t("display.text.lgAbout.underTheStar", {
                    star: data?.output?.star,
                  })}
                {data?.output?.caste &&
                  t("display.text.lgAbout.inTheCaste", {
                    caste: data?.output?.caste,
                  })}
                {data?.output?.clan &&
                  t("display.text.lgAbout.inTheClan", {
                    clan: data?.output?.clan,
                  })}
                {data?.output?.anc_region &&
                  t("display.text.lgAbout.ancestralVillage", {
                    param1: var_gender_pronoun2_caps,
                    param2: var_is,
                    anc_region: data?.output?.anc_region,
                  })}
                {data?.output?.ancestral_title &&
                  t("display.text.lgAbout.familyTitle", {
                    param1: var_gender_pronoun2_caps,
                    ancestral_title: data?.output?.ancestral_title,
                  })}
                {data?.output?.native_language &&
                  t("display.text.lgAbout.nativeLanguage", {
                    param1: var_gender_pronoun2_caps,
                    param2: var_is,
                    native_language: data?.output?.native_language,
                  })}
                {data.output.deceased === "No" &&
                  data?.output?.last_res &&
                  t("display.text.lgAbout.currentResidence", {
                    param1: var_gender_pronoun_caps,
                    last_res: data?.output?.last_res,
                  })}
                {data.output.deceased === "Yes" &&
                  (data?.output?.dod || data?.output?.dod) &&
                  t("display.text.lgAbout.dead", {
                    param1: var_gender_pronoun2_caps,
                  })}
                {data.output.deceased === "Yes" &&
                  data?.output?.dod &&
                  t("display.text.lgAbout.dod", {
                    dod: data?.output?.dod,
                  })}
                {data.output.deceased === "Yes" &&
                  data?.output?.dth_loc &&
                  t("display.text.lgAbout.deathLocation", {
                    dth_loc: data?.output?.dth_loc,
                  })}
                {data.output.deceased === "Yes" &&
                  data?.output?.last_res &&
                  t("display.text.lgAbout.lastResidence", {
                    param1: var_gender_pronoun2_caps,
                    last_res: data?.output?.last_res,
                  })}
                {data.output.deceased === "Yes" &&
                  data?.output?.mortal_remains_location &&
                  t("display.text.lgAbout.mortalRemains", {
                    param1: var_gender_pronoun2_caps,
                    mortal_remains_location:
                      data?.output?.mortal_remains_location,
                  })}
              </Typography>
              {data.output.pers_add_details && (
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "16px",
                    px: 1,
                    pb: 2,
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {data.output.pers_add_details}
                </Typography>
              )}
            </>
          ) : (
            <Typography
              sx={{
                color: "#9095A1",
                fontSize: "16px",
                fontStyle: "italic",
                p: 2,
              }}
              align="center"
              width="100%"
            >
              {t("display.text.text.lgAboutNoInfo")}
            </Typography>
          )} */}
          <Grid container spacing={1.5} sx={{ p: 1 }}>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.dob")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.dob ? formatDate(data.output.dob) : "- -"}{" "}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.birth_time")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.birth_time
                  ? formatTime(data.output.birth_time)
                  : "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.native_language")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.native_language || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.religion")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.religion || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.nation")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.nation || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.birth_cntry")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.birth_cntry || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.anc_region")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.anc_region || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.race")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.race || "- -"}
              </Typography>
            </Grid>
            {(data?.output?.nation?.toLowerCase() === "india" ||
              data?.output?.religion?.toLowerCase() === "Hinduism") && (
              <Grid item xs={6}>
                <Typography sx={{ ...subHeadingStyles }}>
                  {t("display.text.lgAbout.caste")}
                </Typography>
                <Typography sx={{ ...valueStyles }}>
                  {data.output.caste || "- -"}
                </Typography>
              </Grid>
            )}
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.clan")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.clan || "- -"}
              </Typography>
            </Grid>
            {(data?.output?.nation?.toLowerCase() === "india" ||
              data?.output?.religion?.toLowerCase() === "Hinduism") && (
              <Grid item xs={6}>
                <Typography sx={{ ...subHeadingStyles }}>
                  {t("display.text.lgAbout.star")}
                </Typography>
                <Typography sx={{ ...valueStyles }}>
                  {data.output.star || "- -"}
                </Typography>
              </Grid>
            )}
            {data?.output?.deceased?.toLowerCase() === "yes" && (
              <Grid item xs={6}>
                <Typography sx={{ ...subHeadingStyles }}>
                  {t("display.text.lgAbout.dod")}
                </Typography>
                <Typography sx={{ ...valueStyles }}>
                  {data.output.dod ? formatDate(data.output.dod) : "- -"}{" "}
                </Typography>
              </Grid>
            )}
            {data?.output?.deceased?.toLowerCase() === "yes" && (
              <Grid item xs={6}>
                <Typography sx={{ ...subHeadingStyles }}>
                  {t("display.text.lgAbout.last_res")}
                </Typography>
                <Typography sx={{ ...valueStyles }}>
                  {data.output.last_res || "- -"}
                </Typography>
              </Grid>
            )}
            {data?.output?.deceased?.toLowerCase() === "yes" && (
              <Grid item xs={6}>
                <Typography sx={{ ...subHeadingStyles }}>
                  {t("display.text.lgAbout.dth_loc")}
                </Typography>
                <Typography sx={{ ...valueStyles }}>
                  {data.output.dth_loc || "- -"}
                </Typography>
              </Grid>
            )}
            {data?.output?.deceased?.toLowerCase() === "yes" && (
              <Grid item xs={6}>
                <Typography sx={{ ...subHeadingStyles }}>
                  {t("display.text.lgAbout.death_cause")}
                </Typography>
                <Typography sx={{ ...valueStyles }}>
                  {data.output.death_cause || "- -"}
                </Typography>
              </Grid>
            )}
            {data?.output?.deceased?.toLowerCase() === "yes" && (
              <Grid item xs={6}>
                <Typography sx={{ ...subHeadingStyles }}>
                  {t("display.text.lgAbout.mortal_remains")}
                </Typography>
                <Typography sx={{ ...valueStyles }}>
                  {data.output.mortal_remains || "- -"}
                </Typography>
              </Grid>
            )}
            {data?.output?.deceased?.toLowerCase() === "yes" && (
              <Grid item xs={6}>
                <Typography sx={{ ...subHeadingStyles }}>
                  {t("display.text.lgAbout.mortal_remains_location")}
                </Typography>
                <Typography sx={{ ...valueStyles }}>
                  {data.output.mortal_remains_location || "- -"}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.pers_add_details")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.pers_add_details || "- -"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        {data?.output?.spouse_list.length > 0 && (
          <Card sx={{ minHeight: "150px" }}>
            <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
              <Typography sx={{ flex: 1, ...headingStyles }}>
                {t("display.text.text.spouse", {
                  count: data?.output?.spouse_list.length,
                })}
              </Typography>
            </Box>
            <Divider sx={{ width: "100%" }} />
            <Stack spacing={1}>
              {data?.output?.spouse_list.map((item, idx) => {
                return (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={idx}
                  >
                    <Box sx={{ flex: 1, ...headingStyles }}>
                      <Button
                        sx={{ width: "unset", justifyContent: "flex-start" }}
                        onClick={() =>
                          navigate(`/profile/${item.link.split("/")[4]}/about`)
                        }
                      >
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-start" }}
                        >
                          <UserNameCard
                            key={idx}
                            src={item.readURL}
                            name={item.name}
                            relation={formatRelationship(item.spouse_lg_id)}
                            ownerType={findOwner(item.spouse_lg_id)?.owner_type}
                          />
                        </Box>
                      </Button>
                    </Box>

                    {showEditButton && (
                      <IconButton
                        onClick={() => {
                          setSpouseData({ ...item });
                          setEditPromt("spouse");
                        }}
                      >
                        <EditIcon sx={{ fontSize: "16px" }} />
                      </IconButton>
                    )}
                  </Box>
                );
              })}
            </Stack>
          </Card>
        )}
        <Card sx={{ minHeight: "150px" }}>
          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <Typography sx={{ flex: 1, ...headingStyles }}>
              {t("display.text.text.education")}
            </Typography>
            {showEditButton && (
              <IconButton onClick={() => setEditPromt("education")}>
                <EditIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )}
          </Box>
          <Divider sx={{ width: "100%" }} />
          {/* {education ? (
            <>
              <Typography sx={fontStyle}>
                {data?.output?.schools &&
                  t("display.text.lgAbout.school", {
                    first_name: data?.output?.first_name,
                    param1: var_gender_pronoun2,
                    schools: formatPipe(data?.output?.schools),
                  })}
                {data?.output?.colleges &&
                  t("display.text.lgAbout.college", {
                    param1: var_gender_pronoun2_caps,
                    colleges: formatPipe(data?.output?.colleges),
                  })}
                {data?.output?.edu_area &&
                  t("display.text.lgAbout.educationalArea", {
                    param1: var_gender_pronoun_caps,
                    edu_area: formatPipe(data?.output?.edu_area),
                  })}
                {data?.output?.edu_qual &&
                  t("display.text.lgAbout.educationalQualification", {
                    param1: var_gender_pronoun2_caps,
                    edu_qual: formatPipe(data?.output?.edu_qual),
                  })}
              </Typography>
              {data.output.edu_details && (
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "16px",
                    px: 1,
                    pb: 2,
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {data.output.edu_details}
                </Typography>
              )}
            </>
          ) : (
            <Typography
              sx={{
                color: "#9095A1",
                fontSize: "16px",
                fontStyle: "italic",
                p: 2,
              }}
              align="center"
              width="100%"
            >
              {t("display.text.text.lgAboutNoInfo")}
            </Typography>
          )} */}
          <Grid container spacing={1.5} sx={{ p: 1 }}>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.edu_lvl")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.edu_lvl || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.edu_qual")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.edu_qual ? (
                  <ul>
                    {data.output.edu_qual.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.schools")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.schools ? (
                  <ul>
                    {data.output.schools.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.colleges")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.colleges ? (
                  <ul>
                    {data.output.colleges.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.edu_area")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.edu_area ? (
                  <ul>
                    {data.output.edu_area.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.edu_details")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.edu_details || "- -"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ minHeight: "150px" }}>
          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <Typography sx={{ flex: 1, ...headingStyles }}>
              {t("display.text.text.occupation")}
            </Typography>
            {showEditButton && (
              <IconButton onClick={() => setEditPromt("occupation")}>
                <EditIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )}
          </Box>
          <Divider sx={{ width: "100%" }} />
          {/* {occupation ? (
            <>
              <Typography sx={fontStyle}>
                {data?.output?.main_occupation &&
                  t("display.text.lgAbout.mainOccupation", {
                    param1: var_gender_pronoun2_caps,
                    main_occupation: formatPipe(data?.output?.main_occupation),
                  })}
                {data?.output?.sec_occupation && data?.output?.main_occupation
                  ? t("display.text.lgAbout.secondaryOccupation", {
                      param1: var_gender_pronoun,
                      sec_occupation: formatPipe(data?.output?.sec_occupation),
                    })
                  : data?.output?.sec_occupation &&
                    t("display.text.lgAbout.secondaryOccupationMod1", {
                      param1: var_gender_pronoun_caps,
                      sec_occupation: formatPipe(data?.output?.sec_occupation),
                    })}
                {data?.output?.workplaces &&
                  t("display.text.lgAbout.workplaces", {
                    param1: var_gender_pronoun2,
                    param2: var_gender_pronoun,
                    workplaces: formatPipe(data?.output?.workplaces),
                  })}
              </Typography>
              {data.output.occu_details && (
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "16px",
                    px: 1,
                    pb: 2,
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {data.output.occu_details}
                </Typography>
              )}
            </>
          ) : (
            <Typography
              sx={{
                color: "#9095A1",
                fontSize: "16px",
                fontStyle: "italic",
                p: 2,
              }}
              align="center"
              width="100%"
            >
              {t("display.text.text.lgAboutNoInfo")}
            </Typography>
          )} */}
          <Grid container spacing={1.5} sx={{ p: 1 }}>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.main_occupation")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.main_occupation ? (
                  <ul>
                    {data.output.main_occupation.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.sec_occupation")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.sec_occupation ? (
                  <ul>
                    {data.output.sec_occupation.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.workplaces")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.workplaces ? (
                  <ul>
                    {data.output.workplaces.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.occu_details")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.occu_details || "- -"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ minHeight: "150px" }}>
          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <Typography sx={{ flex: 1, ...headingStyles }}>
              {t("display.text.text.health")}
            </Typography>
            {showEditButton && (
              <IconButton onClick={() => setEditPromt("health")}>
                <EditIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )}
          </Box>
          <Divider sx={{ width: "100%" }} />
          {/* {health ? (
            <>
              <Typography sx={fontStyle}>
                {data?.output?.blood_group &&
                  t("display.text.lgAbout.bloodGroup", {
                    first_name: data?.output?.first_name,
                    blood_group: data?.output?.blood_group,
                  })}
                {data?.output?.smoking
                  ? data?.output?.smoking === "Never"
                    ? t("display.text.lgAbout.smokingNone", {
                        param1: var_gender_pronoun_caps,
                      })
                    : data?.output?.smoking === "Experimented"
                    ? t("display.text.lgAbout.experimentSmoking", {
                        param1: var_gender_pronoun_caps,
                      })
                    : t("display.text.lgAbout.smoker", {
                        param1: var_gender_pronoun_caps,
                        smoking: data?.output?.smoking,
                      })
                  : ""}
                {data?.output?.drinking
                  ? data?.output?.drinking === "Never"
                    ? t("display.text.lgAbout.drinkingNone", {
                        param1: var_gender_pronoun_caps,
                      })
                    : data?.output?.drinking === "Experimented"
                    ? t("display.text.lgAbout.experimentDrinking", {
                        param1: var_gender_pronoun_caps,
                      })
                    : t("display.text.lgAbout.drinking", {
                        param1: var_gender_pronoun_caps,
                        drinking: data?.output?.drinking,
                      })
                  : ""}
                {data?.output?.chronic_illness &&
                  t("display.text.lgAbout.illness", {
                    param1: var_gender_pronoun_caps,
                    chronic_illness: formatPipe(data?.output?.chronic_illness),
                  })}
                {data?.output?.physical_activity &&
                  t("display.text.lgAbout.activity", {
                    param1: var_gender_pronoun2_caps,
                    physical_activity: formatPipe(
                      data?.output?.physical_activity
                    ),
                  })}
              </Typography>
              {data.output.health_details && (
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "16px",
                    px: 1,
                    pb: 2,
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {data.output.health_details}
                </Typography>
              )}
            </>
          ) : (
            <Typography
              sx={{
                color: "#9095A1",
                fontSize: "16px",
                fontStyle: "italic",
                p: 2,
              }}
              align="center"
              width="100%"
            >
              {t("display.text.text.lgAboutNoInfo")}
            </Typography>
          )} */}
          <Grid container spacing={1.5} sx={{ p: 1 }}>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.blood_group")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.blood_group || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.smoking")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.smoking || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.drinking")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.drinking || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.physical_activity")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.physical_activity || "- -"}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.chronic_illness")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.chronic_illness ? (
                  <ul>
                    {data.output.chronic_illness.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.health_details")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.health_details || "- -"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ minHeight: "150px" }}>
          <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
            <Typography sx={{ flex: 1, ...headingStyles }}>
              {t("display.text.text.personality")}
            </Typography>
            {showEditButton && (
              <IconButton onClick={() => setEditPromt("personality")}>
                <EditIcon sx={{ fontSize: "16px" }} />
              </IconButton>
            )}
          </Box>
          <Divider sx={{ width: "100%" }} />
          {/* {personality ? (
            <>
              <Typography sx={fontStyle}>
                {data?.output?.best_qual &&
                  t("display.text.lgAbout.bestQual", {
                    first_name: data?.output?.first_name,
                    best_qual: formatPipe(data?.output?.best_qual),
                    param1: var_gender_pronoun2,
                  })}
                {data?.output?.worst_qual &&
                  data?.output?.best_qual &&
                  t("display.text.lgAbout.worstQualMod1", {
                    param1: var_gender_pronoun,
                    worst_qual: formatPipe(data?.output?.worst_qual),
                  })}
                {data?.output?.worst_qual &&
                  t("display.text.lgAbout.worstQualMod2", {
                    first_name: data?.output?.first_name,
                    worst_qual: formatPipe(data?.output?.worst_qual),
                  })}
                {data?.output?.hobbies &&
                  t("display.text.lgAbout.hobbies", {
                    param1: var_gender_pronoun2_caps,
                    hobbies: formatPipe(data?.output?.hobbies),
                  })}
                {data?.output?.fav_food &&
                  t("display.text.lgAbout.fav_food", {
                    param1: var_gender_pronoun_caps,
                    fav_food: formatPipe(data?.output?.fav_food),
                  })}
                {data?.output?.fav_sports &&
                  t("display.text.lgAbout.fav_sports", {
                    param1: var_gender_pronoun_caps,
                    fav_sports: formatPipe(data?.output?.fav_sports),
                  })}
                {data?.output?.fav_books &&
                  t("display.text.lgAbout.fav_books", {
                    param1: var_gender_pronoun2_caps,
                    fav_books: formatPipe(data?.output?.fav_books),
                  })}
                {data?.output?.fav_music &&
                  t("display.text.lgAbout.fav_music", {
                    param1: var_gender_pronoun_caps,
                    fav_music: formatPipe(data?.output?.fav_music),
                  })}
              </Typography>
              {data.output.persntly_add_details && (
                <Typography
                  sx={{
                    color: "#000000",
                    fontSize: "16px",
                    px: 1,
                    pb: 2,
                    wordWrap: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {data.output.persntly_add_details}
                </Typography>
              )}
            </>
          ) : (
            <Typography
              sx={{
                color: "#9095A1",
                fontSize: "16px",
                fontStyle: "italic",
                p: 2,
              }}
              align="center"
              width="100%"
            >
              {t("display.text.text.lgAboutNoInfo")}
            </Typography>
          )} */}
          <Grid container spacing={1.5} sx={{ p: 1 }}>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.best_qual")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.best_qual ? (
                  <ul>
                    {data.output.best_qual.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.worst_qual")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.worst_qual ? (
                  <ul>
                    {data.output.worst_qual.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.hobbies")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.hobbies ? (
                  <ul>
                    {data.output.hobbies.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.fav_food")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.fav_food ? (
                  <ul>
                    {data.output.fav_food.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.fav_sports")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.fav_sports ? (
                  <ul>
                    {data.output.fav_sports.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.fav_books")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.fav_books ? (
                  <ul>
                    {data.output.fav_books.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.fav_music")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.fav_music ? (
                  <ul>
                    {data.output.fav_music.split("|").map((item, idx) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                ) : (
                  "- -"
                )}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ ...subHeadingStyles }}>
                {t("display.text.lgAbout.persntly_add_details")}
              </Typography>
              <Typography sx={{ ...valueStyles }}>
                {data.output.persntly_add_details || "- -"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Stack>
      {editPromt === "about" && (
        <LgAbout
          mode={"edit"}
          open={editPromt === "about"}
          close={() => setEditPromt(false)}
          {...data.output}
        />
      )}
      {editPromt === "personal" && (
        <LgPersonal
          mode={"edit"}
          open={editPromt === "personal"}
          close={() => setEditPromt(false)}
          {...data.output}
        />
      )}
      {editPromt === "spouse" && (
        <LgSpouse
          lg_url={data?.output?.lg_url}
          lg_id={data?.output?.lg_id}
          open={editPromt === "spouse"}
          close={() => {
            setEditPromt(false);
            setSpouseData(null);
          }}
          {...spouseData}
        />
      )}
      {editPromt === "education" && (
        <LgEducation
          mode={"edit"}
          open={editPromt === "education"}
          close={() => setEditPromt(false)}
          {...data.output}
        />
      )}
      {editPromt === "occupation" && (
        <LgOccupation
          mode={"edit"}
          open={editPromt === "occupation"}
          close={() => setEditPromt(false)}
          {...data.output}
        />
      )}
      {editPromt === "health" && (
        <LgHealth
          mode={"edit"}
          open={editPromt === "health"}
          close={() => setEditPromt(false)}
          {...data.output}
        />
      )}
      {editPromt === "personality" && (
        <LgPersonality
          mode={"edit"}
          open={editPromt === "personality"}
          close={() => setEditPromt(false)}
          {...data.output}
        />
      )}
    </Card>
  );
};

export const assetSource = "https://stor1.famscape.com/openstore/images";

export const emoji = {
  anguished: `${assetSource}/emoji_anguished.svg`,
  surprised: `${assetSource}/emoji_surprised.svg`,
  brokenHeart: `${assetSource}/emoji_brokenHeart.svg`,
  celebrate: `${assetSource}/emoji_celebrate.svg`,
  cry: `${assetSource}/emoji_cry.svg`,
  delighted: `${assetSource}/emoji_delighted.svg`,
  happy: `${assetSource}/emoji_happy.svg`,
  takeCare: `${assetSource}/emoji_takeCare.svg`,
  treasure2: `${assetSource}/emoji_treasure2.svg`,
  nostalgia: `${assetSource}/emoji_nostalgia.svg`,
  goldenTimes: `${assetSource}/emoji_goldenTimes.svg`,
  loveIt: `${assetSource}/emoji_loveIt.svg`,
  cuddlyCute: `${assetSource}/emoji_cuddlyCute.svg`,
  timeless: `${assetSource}/emoji_timeless.svg`,
  claps: `${assetSource}/emoji_claps.svg`,
  // award: awar.svgd,
  highVoltage: `${assetSource}/emoji_highVoltage.svg`,
  winners: `${assetSource}/emoji_winners.svg`,
  onFire: `${assetSource}/emoji_onFire.svg`,
  thumbsUp: `${assetSource}/emoji_thumbsUp.svg`,
  perfect: `${assetSource}/emoji_perfect.svg`,
  rofl: `${assetSource}/emoji_rofl.svg`,
  sad: `${assetSource}/emoji_sad.svg`,
  speechless: `${assetSource}/emoji_speechless.svg`,
  touching: `${assetSource}/emoji_touching.svg`,
  hatsOff: `${assetSource}/emoji_hatsOff.svg`,
  stars: `${assetSource}/emoji_stars.svg`,
  sage: `${assetSource}/emoji_sage.svg`,
  treasure: `${assetSource}/emoji_treasure.svg`,
  thankYou: `${assetSource}/emoji_thankYou.svg`,
  hi5: `${assetSource}/emoji_hi5.svg`,
  praying: `${assetSource}/emoji_praying.svg`,
  goodLuck: `${assetSource}/emoji_goodLuck.svg`,
  wishSuccess: `${assetSource}/emoji_wishSuccess.svg`,
  cool: `${assetSource}/emoji_cool.svg`,
  blessYou: `${assetSource}/emoji_blessYou.svg`,
  party2: `${assetSource}/emoji_party2.svg`,
  cheering: `${assetSource}/emoji_cheering.svg`,
  hugs: `${assetSource}/emoji_hugs.svg`,
  birdsOfFeather: `${assetSource}/emoji_birdsOfFeather.svg`,
  realHero: `${assetSource}/emoji_realHero.svg`,
  pillarOfStrength: `${assetSource}/emoji_pillarOfStrength.svg`,
  heartOfGold: `${assetSource}/emoji_heartOfGold.svg`,
  gemOfAPerson: `${assetSource}/emoji_gemOfAPerson.svg`,
  guidingLights: `${assetSource}/emoji_guidingLights.svg`,
  respect: `${assetSource}/emoji_respect.svg`,
  fireCracker: `${assetSource}/emoji_fireCracker.svg`,
  connected: `${assetSource}/emoji_connected.svg`,
  admiration: `${assetSource}/emoji_admiration.svg`,
  sunShine: `${assetSource}/emoji_sunShine.svg`,
  guardianAngel: `${assetSource}/emoji_guardianAngel.svg`,
  seekBlessing: `${assetSource}/emoji_seekBlessing.svg`,
};

export const relationshipIcons = {
  parents: `${assetSource}/img_parents`,
  grandParents: `${assetSource}/img_grandParents`,
  siblings: `${assetSource}/img_siblings`,
  stepSiblings: `${assetSource}/img_stepSiblings`,
  uncleAunt: `${assetSource}/img_uncleAunt`,
  spouse: `${assetSource}/img_spouse`,
  children: `${assetSource}/img_children`,
  grandChildren: `${assetSource}/img_grandChildren`,
  cousin: `${assetSource}/img_cousin`,
  inlaws: `${assetSource}/img_inlaws`,
  nieceNephew: `${assetSource}/img_nieceNephew`,
  others: `${assetSource}/img_others`,
};

export const introCardImage = {
  nudgeAddRelations: `${assetSource}/img_nudgeAddRelations`,
  nudgeNewPost: `${assetSource}/img_nudgeNewPost`,
  nudgeProfileDetails: `${assetSource}/img_nudgeProfileDetails`,
};

export const welcomepromtImage = {
  0: `${assetSource}/img_welcomePromt_0`,
  1: `${assetSource}/img_welcomePromt_1`,
  2: `${assetSource}/img_welcomePromt_2`,
  3: `${assetSource}/img_welcomePromt_3`,
};

export const bondingBadge = { bonding: `${assetSource}/badge_beginer.svg` };

export const ownerIcons = {
  Owner: `${assetSource}/icon_ownerIcon.svg`,
  Collab: `${assetSource}/icon_collabIcon.svg`,
};

export const badgeSrc = { beginer: `${assetSource}/badge_beginer.svg` };

export const eventTypesIcons = {
  le: `${assetSource}/icon_le.svg`,
  mem: `${assetSource}/icon_mem.svg`,
  wish: `${assetSource}/icon_wish.svg`,
  anc: `${assetSource}/icon_anc.svg`,
  ach: `${assetSource}/icon_ach.svg`,
  impress: `${assetSource}/icon_impress.svg`,
  wisdom: `${assetSource}/icon_wisdom.svg`,
  random: `${assetSource}/icon_random.svg`,
};

export const lifeEventTypes = [
  {
    value: "birth",
    label: "Birth",
  },
  {
    value: "joinschool",
    label: "Enrolled in School",
  },
  {
    value: "college",
    label: "Enrolled in College/University",
  },
  {
    value: "graduate",
    label: "Graduated",
  },
  {
    value: "learnskill",
    label: "Acquired new skill",
  },
  {
    value: "sport",
    label: "Started a new sport",
  },
  {
    value: "crush",
    label: "Had a crush",
  },
  {
    value: "vehicle",
    label: "Bought a vehicle",
  },
  {
    value: "job",
    label: "Joined a new Job",
  },
  {
    value: "house",
    label: "Bought a house",
  },
  {
    value: "careerup",
    label: "Career Advancement",
  },
  {
    value: "careerdown",
    label: "Career Downturn",
  },
  {
    value: "careerchange",
    label: "Career Change",
  },
  {
    value: "love",
    label: "Fell in Love",
  },
  {
    value: "engaged",
    label: "Got Engaged",
  },
  {
    value: "wedding",
    label: "Got Married",
  },
  {
    value: "divorce",
    label: "Got Divorced",
  },
  {
    value: "bereaved",
    label: "Lost a loved one",
  },
  {
    value: "pregnant",
    label: "Became Pregnant",
  },
  {
    value: "parent",
    label: "Became a Parent",
  },
  {
    value: "celebrity",
    label: "Met a Celebrity",
  },
  {
    value: "mentor",
    label: "Met a Guru / Mentor",
  },
  {
    value: "friend",
    label: "Met a friend",
  },
  {
    value: "pet",
    label: "Got a New Pet",
  },
  {
    value: "relocation",
    label: "Moved to New City",
  },
  {
    value: "soldprop",
    label: "Sold a house / property",
  },
  {
    value: "retire",
    label: "Retired",
  },
  {
    value: "sickness",
    label: "Fell sick",
  },
  {
    value: "volunteer",
    label: "Joined social / political cause",
  },
  {
    value: "accident",
    label: "Met with Accident",
  },
  {
    value: "wisdom",
    label: "Moment of Self Discovery",
  },
  {
    value: "gain",
    label: "Financial gain",
  },
  {
    value: "loss",
    label: "Financial loss",
  },
  {
    value: "legal",
    label: "Faced legal case",
  },
  {
    value: "hobby",
    label: "Started a new hobby",
  },
  {
    value: "award",
    label: "Won a award",
  },
  {
    value: "TBD",
    label: "Started a healthy habit",
  },
  {
    value: "TBD",
    label: "Caught a bad habit",
  },
  {
    value: "emergency",
    label: "Faced Medical Emergency",
  },
  {
    value: "General",
    label: "Low Phase of Life",
  },
  {
    value: "celebrate",
    label: "Other - Celebration",
  },
  {
    value: "grief",
    label: "Other - Grief",
  },
  {
    value: "turningpt",
    label: "Other - Turning point",
  },
];

export const visibilityTypes = [
  {
    value: "priv",
    label: "Private",
  },
  {
    value: "imm",
    label: "Immediate",
  },
  {
    value: "ext",
    label: "Extended",
  },
  {
    value: "1c",
    label: "1st cousins",
  },
  {
    value: "dist",
    label: "All Relatives",
  },
];

export const fileTypes = [
  {
    value: "audio",
    label: "Audio",
  },
  {
    value: "video",
    label: "Video",
  },
  {
    value: "Video - Large",
    label: "Video - Large",
  },
  {
    value: "image",
    label: "Image",
  },
  {
    value: "application",
    label: "Documents",
  },
  {
    value: "link",
    label: "Youtube Link",
  },
  {
    value: "Other Links",
    label: "Other Links",
  },
  {
    value: "none",
    label: "None",
  },
];

export const announcementTypes = [
  {
    value: "Happy News",
    label: "Happy News",
  },
  {
    value: "Sad news",
    label: "Sad news",
  },
  {
    value: "Life Update",
    label: "Life Update",
  },
  {
    value: "Invitation",
    label: "Invitation",
  },
  {
    value: "Seek Help",
    label: "Seek Help",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const wisdomType = [
  { value: "food", label: "Food", color: "#1fb73b" },
  { value: "health", label: "Health", color: "#beac78" },
  { value: "life Lesson", label: "Life Lesson", color: "#56887d" },
  { value: "spiritual", label: "Spiritual", color: "#56887d" },
  { value: "add Profession", label: "Add Profession", color: "#0000FF" },
  { value: "family", label: "Family", color: "#774177" },
  { value: "other", label: "Other", color: "#FFA500" },
  { value: "personal", label: "Personal", color: "#800000" },
  { value: "financial", label: "Financial", color: "#1a652a" },
];

export const relationType = [
  {
    value: "Mother",
    label: "Mother",
  },
  {
    value: "Father",
    label: "Father",
  },
  {
    value: "Son",
    label: "Son",
  },
  {
    value: "Daughter",
    label: "Daughter",
  },
  {
    value: "Brother",
    label: "Brother",
  },
  {
    value: "Sister",
    label: "Sister",
  },
  {
    value: "Wife",
    label: "Wife",
  },
  {
    value: "Husband",
    label: "Husband",
  },
];

export const genderList = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
];

export const deceasedDropdown = [
  {
    value: "No",
    label: "Yes",
  },
  {
    value: "Yes",
    label: "No",
  },
];

export const countriesList = [
  {
    value: "Andorra",
    label: "Andorra",
  },
  {
    value: "United Arab Emirates",
    label: "United Arab Emirates",
  },
  {
    value: "Afghanistan",
    label: "Afghanistan",
  },
  {
    value: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
  },
  {
    value: "Anguilla",
    label: "Anguilla",
  },
  {
    value: "Albania",
    label: "Albania",
  },
  {
    value: "Armenia",
    label: "Armenia",
  },
  {
    value: "Angola",
    label: "Angola",
  },
  {
    value: "Antarctica",
    label: "Antarctica",
  },
  {
    value: "Argentina",
    label: "Argentina",
  },
  {
    value: "American Samoa",
    label: "American Samoa",
  },
  {
    value: "Austria",
    label: "Austria",
  },
  {
    value: "Australia",
    label: "Australia",
  },
  {
    value: "Aruba",
    label: "Aruba",
  },
  {
    value: "Alland Islands",
    label: "Alland Islands",
  },
  {
    value: "Azerbaijan",
    label: "Azerbaijan",
  },
  {
    value: "Bosnia and Herzegovina",
    label: "Bosnia and Herzegovina",
  },
  {
    value: "Barbados",
    label: "Barbados",
  },
  {
    value: "Bangladesh",
    label: "Bangladesh",
  },
  {
    value: "Belgium",
    label: "Belgium",
  },
  {
    value: "Burkina Faso",
    label: "Burkina Faso",
  },
  {
    value: "Bulgaria",
    label: "Bulgaria",
  },
  {
    value: "Bahrain",
    label: "Bahrain",
  },
  {
    value: "Burundi",
    label: "Burundi",
  },
  {
    value: "Benin",
    label: "Benin",
  },
  {
    value: "Saint Barthelemy",
    label: "Saint Barthelemy",
  },
  {
    value: "Bermuda",
    label: "Bermuda",
  },
  {
    value: "Brunei Darussalam",
    label: "Brunei Darussalam",
  },
  {
    value: "Bolivia",
    label: "Bolivia",
  },
  {
    value: "Brazil",
    label: "Brazil",
  },
  {
    value: "Bahamas",
    label: "Bahamas",
  },
  {
    value: "Bhutan",
    label: "Bhutan",
  },
  {
    value: "Bouvet Island",
    label: "Bouvet Island",
  },
  {
    value: "Botswana",
    label: "Botswana",
  },
  {
    value: "Belarus",
    label: "Belarus",
  },
  {
    value: "Belize",
    label: "Belize",
  },
  {
    value: "Canada",
    label: "Canada",
  },
  {
    value: "Cocos (Keeling) Islands",
    label: "Cocos (Keeling) Islands",
  },
  {
    value: "Congo, Democratic Republic of the",
    label: "Congo, Democratic Republic of the",
  },
  {
    value: "Central African Republic",
    label: "Central African Republic",
  },
  {
    value: "Congo, Republic of the",
    label: "Congo, Republic of the",
  },
  {
    value: "Switzerland",
    label: "Switzerland",
  },
  {
    value: "Cote d'Ivoire",
    label: "Cote d'Ivoire",
  },
  {
    value: "Cook Islands",
    label: "Cook Islands",
  },
  {
    value: "Chile",
    label: "Chile",
  },
  {
    value: "Cameroon",
    label: "Cameroon",
  },
  {
    value: "China",
    label: "China",
  },
  {
    value: "Colombia",
    label: "Colombia",
  },
  {
    value: "Costa Rica",
    label: "Costa Rica",
  },
  {
    value: "Cuba",
    label: "Cuba",
  },
  {
    value: "Cape Verde",
    label: "Cape Verde",
  },
  {
    value: "Curacao",
    label: "Curacao",
  },
  {
    value: "Christmas Island",
    label: "Christmas Island",
  },
  {
    value: "Cyprus",
    label: "Cyprus",
  },
  {
    value: "Czech Republic",
    label: "Czech Republic",
  },
  {
    value: "Germany",
    label: "Germany",
  },
  {
    value: "Djibouti",
    label: "Djibouti",
  },
  {
    value: "Denmark",
    label: "Denmark",
  },
  {
    value: "Dominica",
    label: "Dominica",
  },
  {
    value: "Dominican Republic",
    label: "Dominican Republic",
  },
  {
    value: "Algeria",
    label: "Algeria",
  },
  {
    value: "Ecuador",
    label: "Ecuador",
  },
  {
    value: "Estonia",
    label: "Estonia",
  },
  {
    value: "Egypt",
    label: "Egypt",
  },
  {
    value: "Western Sahara",
    label: "Western Sahara",
  },
  {
    value: "Eritrea",
    label: "Eritrea",
  },
  {
    value: "Spain",
    label: "Spain",
  },
  {
    value: "Ethiopia",
    label: "Ethiopia",
  },
  {
    value: "Finland",
    label: "Finland",
  },
  {
    value: "Fiji",
    label: "Fiji",
  },
  {
    value: "Falkland Islands (Malvinas)",
    label: "Falkland Islands (Malvinas)",
  },
  {
    value: "Micronesia, Federated States of",
    label: "Micronesia, Federated States of",
  },
  {
    value: "Faroe Islands",
    label: "Faroe Islands",
  },
  {
    value: "France",
    label: "France",
  },
  {
    value: "Gabon",
    label: "Gabon",
  },
  {
    value: "United Kingdom",
    label: "United Kingdom",
  },
  {
    value: "Grenada",
    label: "Grenada",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "French Guiana",
    label: "French Guiana",
  },
  {
    value: "Guernsey",
    label: "Guernsey",
  },
  {
    value: "Ghana",
    label: "Ghana",
  },
  {
    value: "Gibraltar",
    label: "Gibraltar",
  },
  {
    value: "Greenland",
    label: "Greenland",
  },
  {
    value: "Gambia",
    label: "Gambia",
  },
  {
    value: "Guinea",
    label: "Guinea",
  },
  {
    value: "Guadeloupe",
    label: "Guadeloupe",
  },
  {
    value: "Equatorial Guinea",
    label: "Equatorial Guinea",
  },
  {
    value: "Greece",
    label: "Greece",
  },
  {
    value: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    value: "Guatemala",
    label: "Guatemala",
  },
  {
    value: "Guam",
    label: "Guam",
  },
  {
    value: "Guinea-Bissau",
    label: "Guinea-Bissau",
  },
  {
    value: "Guyana",
    label: "Guyana",
  },
  {
    value: "Hong Kong",
    label: "Hong Kong",
  },
  {
    value: "Heard Island and McDonald Islands",
    label: "Heard Island and McDonald Islands",
  },
  {
    value: "Honduras",
    label: "Honduras",
  },
  {
    value: "Croatia",
    label: "Croatia",
  },
  {
    value: "Haiti",
    label: "Haiti",
  },
  {
    value: "Hungary",
    label: "Hungary",
  },
  {
    value: "Indonesia",
    label: "Indonesia",
  },
  {
    value: "Ireland",
    label: "Ireland",
  },
  {
    value: "Israel",
    label: "Israel",
  },
  {
    value: "Isle of Man",
    label: "Isle of Man",
  },
  {
    value: "India",
    label: "India",
  },
  {
    value: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
  },
  {
    value: "Iraq",
    label: "Iraq",
  },
  {
    value: "Iran, Islamic Republic of",
    label: "Iran, Islamic Republic of",
  },
  {
    value: "Iceland",
    label: "Iceland",
  },
  {
    value: "Italy",
    label: "Italy",
  },
  {
    value: "Jersey",
    label: "Jersey",
  },
  {
    value: "Jamaica",
    label: "Jamaica",
  },
  {
    value: "Jordan",
    label: "Jordan",
  },
  {
    value: "Japan",
    label: "Japan",
  },
  {
    value: "Kenya",
    label: "Kenya",
  },
  {
    value: "Kyrgyzstan",
    label: "Kyrgyzstan",
  },
  {
    value: "Cambodia",
    label: "Cambodia",
  },
  {
    value: "Kiribati",
    label: "Kiribati",
  },
  {
    value: "Comoros",
    label: "Comoros",
  },
  {
    value: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
  },
  {
    value: "Korea, Democratic People's Republic of",
    label: "Korea, Democratic People's Republic of",
  },
  {
    value: "Korea, Republic of",
    label: "Korea, Republic of",
  },
  {
    value: "Kuwait",
    label: "Kuwait",
  },
  {
    value: "Cayman Islands",
    label: "Cayman Islands",
  },
  {
    value: "Kazakhstan",
    label: "Kazakhstan",
  },
  {
    value: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
  },
  {
    value: "Lebanon",
    label: "Lebanon",
  },
  {
    value: "Saint Lucia",
    label: "Saint Lucia",
  },
  {
    value: "Liechtenstein",
    label: "Liechtenstein",
  },
  {
    value: "Sri Lanka",
    label: "Sri Lanka",
  },
  {
    value: "Liberia",
    label: "Liberia",
  },
  {
    value: "Lesotho",
    label: "Lesotho",
  },
  {
    value: "Lithuania",
    label: "Lithuania",
  },
  {
    value: "Luxembourg",
    label: "Luxembourg",
  },
  {
    value: "Latvia",
    label: "Latvia",
  },
  {
    value: "Libya",
    label: "Libya",
  },
  {
    value: "Morocco",
    label: "Morocco",
  },
  {
    value: "Monaco",
    label: "Monaco",
  },
  {
    value: "Moldova, Republic of",
    label: "Moldova, Republic of",
  },
  {
    value: "Montenegro",
    label: "Montenegro",
  },
  {
    value: "Saint Martin (French part)",
    label: "Saint Martin (French part)",
  },
  {
    value: "Madagascar",
    label: "Madagascar",
  },
  {
    value: "Marshall Islands",
    label: "Marshall Islands",
  },
  {
    value: "Macedonia, the Former Yugoslav Republic of",
    label: "Macedonia, the Former Yugoslav Republic of",
  },
  {
    value: "Mali",
    label: "Mali",
  },
  {
    value: "Myanmar",
    label: "Myanmar",
  },
  {
    value: "Mongolia",
    label: "Mongolia",
  },
  {
    value: "Macao",
    label: "Macao",
  },
  {
    value: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
  },
  {
    value: "Martinique",
    label: "Martinique",
  },
  {
    value: "Mauritania",
    label: "Mauritania",
  },
  {
    value: "Montserrat",
    label: "Montserrat",
  },
  {
    value: "Malta",
    label: "Malta",
  },
  {
    value: "Mauritius",
    label: "Mauritius",
  },
  {
    value: "Maldives",
    label: "Maldives",
  },
  {
    value: "Malawi",
    label: "Malawi",
  },
  {
    value: "Mexico",
    label: "Mexico",
  },
  {
    value: "Malaysia",
    label: "Malaysia",
  },
  {
    value: "Mozambique",
    label: "Mozambique",
  },
  {
    value: "Namibia",
    label: "Namibia",
  },
  {
    value: "New Caledonia",
    label: "New Caledonia",
  },
  {
    value: "Niger",
    label: "Niger",
  },
  {
    value: "Norfolk Island",
    label: "Norfolk Island",
  },
  {
    value: "Nigeria",
    label: "Nigeria",
  },
  {
    value: "Nicaragua",
    label: "Nicaragua",
  },
  {
    value: "Netherlands",
    label: "Netherlands",
  },
  {
    value: "Norway",
    label: "Norway",
  },
  {
    value: "Nepal",
    label: "Nepal",
  },
  {
    value: "Nauru",
    label: "Nauru",
  },
  {
    value: "Niue",
    label: "Niue",
  },
  {
    value: "New Zealand",
    label: "New Zealand",
  },
  {
    value: "Oman",
    label: "Oman",
  },
  {
    value: "Panama",
    label: "Panama",
  },
  {
    value: "Peru",
    label: "Peru",
  },
  {
    value: "French Polynesia",
    label: "French Polynesia",
  },
  {
    value: "Papua New Guinea",
    label: "Papua New Guinea",
  },
  {
    value: "Philippines",
    label: "Philippines",
  },
  {
    value: "Pakistan",
    label: "Pakistan",
  },
  {
    value: "Poland",
    label: "Poland",
  },
  {
    value: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
  },
  {
    value: "Pitcairn",
    label: "Pitcairn",
  },
  {
    value: "Puerto Rico",
    label: "Puerto Rico",
  },
  {
    value: "Palestine, State of",
    label: "Palestine, State of",
  },
  {
    value: "Portugal",
    label: "Portugal",
  },
  {
    value: "Palau",
    label: "Palau",
  },
  {
    value: "Paraguay",
    label: "Paraguay",
  },
  {
    value: "Qatar",
    label: "Qatar",
  },
  {
    value: "Reunion",
    label: "Reunion",
  },
  {
    value: "Romania",
    label: "Romania",
  },
  {
    value: "Serbia",
    label: "Serbia",
  },
  {
    value: "Russian Federation",
    label: "Russian Federation",
  },
  {
    value: "Rwanda",
    label: "Rwanda",
  },
  {
    value: "Saudi Arabia",
    label: "Saudi Arabia",
  },
  {
    value: "Solomon Islands",
    label: "Solomon Islands",
  },
  {
    value: "Seychelles",
    label: "Seychelles",
  },
  {
    value: "Sudan",
    label: "Sudan",
  },
  {
    value: "Sweden",
    label: "Sweden",
  },
  {
    value: "Singapore",
    label: "Singapore",
  },
  {
    value: "Saint Helena",
    label: "Saint Helena",
  },
  {
    value: "Slovenia",
    label: "Slovenia",
  },
  {
    value: "Svalbard and Jan Mayen",
    label: "Svalbard and Jan Mayen",
  },
  {
    value: "Slovakia",
    label: "Slovakia",
  },
  {
    value: "Sierra Leone",
    label: "Sierra Leone",
  },
  {
    value: "San Marino",
    label: "San Marino",
  },
  {
    value: "Senegal",
    label: "Senegal",
  },
  {
    value: "Somalia",
    label: "Somalia",
  },
  {
    value: "Suriname",
    label: "Suriname",
  },
  {
    value: "South Sudan",
    label: "South Sudan",
  },
  {
    value: "Sao Tome and Principe",
    label: "Sao Tome and Principe",
  },
  {
    value: "El Salvador",
    label: "El Salvador",
  },
  {
    value: "Sint Maarten (Dutch part)",
    label: "Sint Maarten (Dutch part)",
  },
  {
    value: "Syrian Arab Republic",
    label: "Syrian Arab Republic",
  },
  {
    value: "Swaziland",
    label: "Swaziland",
  },
  {
    value: "Turks and Caicos Islands",
    label: "Turks and Caicos Islands",
  },
  {
    value: "Chad",
    label: "Chad",
  },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
  },
  {
    value: "Togo",
    label: "Togo",
  },
  {
    value: "Thailand",
    label: "Thailand",
  },
  {
    value: "Tajikistan",
    label: "Tajikistan",
  },
  {
    value: "Tokelau",
    label: "Tokelau",
  },
  {
    value: "Timor-Leste",
    label: "Timor-Leste",
  },
  {
    value: "Turkmenistan",
    label: "Turkmenistan",
  },
  {
    value: "Tunisia",
    label: "Tunisia",
  },
  {
    value: "Tonga",
    label: "Tonga",
  },
  {
    value: "Turkey",
    label: "Turkey",
  },
  {
    value: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
  },
  {
    value: "Tuvalu",
    label: "Tuvalu",
  },
  {
    value: "Taiwan, Province of China",
    label: "Taiwan, Province of China",
  },
  {
    value: "United Republic of Tanzania",
    label: "United Republic of Tanzania",
  },
  {
    value: "Ukraine",
    label: "Ukraine",
  },
  {
    value: "Uganda",
    label: "Uganda",
  },
  {
    value: "United States",
    label: "United States",
  },
  {
    value: "Uruguay",
    label: "Uruguay",
  },
  {
    value: "Uzbekistan",
    label: "Uzbekistan",
  },
  {
    value: "Holy See (Vatican City State)",
    label: "Holy See (Vatican City State)",
  },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  {
    value: "Venezuela",
    label: "Venezuela",
  },
  {
    value: "British Virgin Islands",
    label: "British Virgin Islands",
  },
  {
    value: "US Virgin Islands",
    label: "US Virgin Islands",
  },
  {
    value: "Vietnam",
    label: "Vietnam",
  },
  {
    value: "Vanuatu",
    label: "Vanuatu",
  },
  {
    value: "Wallis and Futuna",
    label: "Wallis and Futuna",
  },
  {
    value: "Samoa",
    label: "Samoa",
  },
  {
    value: "Kosovo",
    label: "Kosovo",
  },
  {
    value: "Yemen",
    label: "Yemen",
  },
  {
    value: "Mayotte",
    label: "Mayotte",
  },
  {
    value: "South Africa",
    label: "South Africa",
  },
  {
    value: "Zambia",
    label: "Zambia",
  },
  {
    value: "Zimbabwe",
    label: "Zimbabwe",
  },
];

export const languageList = [
  {
    value: "Akan",
    label: "Akan",
  },
  {
    value: "Amharic",
    label: "Amharic",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
  {
    value: "Assamese",
    label: "Assamese",
  },
  {
    value: "Awadhi",
    label: "Awadhi",
  },
  {
    value: "Azerbaijani",
    label: "Azerbaijani",
  },
  {
    value: "Balochi",
    label: "Balochi",
  },
  {
    value: "Belarusian",
    label: "Belarusian",
  },
  {
    value: "Bengali",
    label: "Bengali",
  },
  {
    value: "Bhojpuri",
    label: "Bhojpuri",
  },
  {
    value: "Burmese",
    label: "Burmese",
  },
  {
    value: "Cebuano (Visayan)",
    label: "Cebuano (Visayan)",
  },
  {
    value: "Chewa",
    label: "Chewa",
  },
  {
    value: "Chhattisgarhi",
    label: "Chhattisgarhi",
  },
  {
    value: "Chittagonian",
    label: "Chittagonian",
  },
  {
    value: "Czech",
    label: "Czech",
  },
  {
    value: "Deccan",
    label: "Deccan",
  },
  {
    value: "Dhundhari",
    label: "Dhundhari",
  },
  {
    value: "Dutch",
    label: "Dutch",
  },
  {
    value: "Eastern Min (inc. Fuzhou dialect)",
    label: "Eastern Min (inc. Fuzhou dialect)",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "Fula",
    label: "Fula",
  },
  {
    value: "Gan",
    label: "Gan",
  },
  {
    value: "German",
    label: "German",
  },
  {
    value: "Greek",
    label: "Greek",
  },
  {
    value: "Gujarati",
    label: "Gujarati",
  },
  {
    value: "Haitian Creole",
    label: "Haitian Creole",
  },
  {
    value: "Hakka",
    label: "Hakka",
  },
  {
    value: "Haryanvi",
    label: "Haryanvi",
  },
  {
    value: "Hausa",
    label: "Hausa",
  },
  {
    value: "Hiligaynon/Ilonggo (Visayan)",
    label: "Hiligaynon/Ilonggo (Visayan)",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "Hmong",
    label: "Hmong",
  },
  {
    value: "Hungarian",
    label: "Hungarian",
  },
  {
    value: "Igbo",
    label: "Igbo",
  },
  {
    value: "Ilocano",
    label: "Ilocano",
  },
  {
    value: "Italian",
    label: "Italian",
  },
  {
    value: "Japanese",
    label: "Japanese",
  },
  {
    value: "Javanese",
    label: "Javanese",
  },
  {
    value: "Jin",
    label: "Jin",
  },
  {
    value: "Kannada",
    label: "Kannada",
  },
  {
    value: "Kazakh",
    label: "Kazakh",
  },
  {
    value: "Khmer",
    label: "Khmer",
  },
  {
    value: "Kinyarwanda",
    label: "Kinyarwanda",
  },
  {
    value: "Kirundi",
    label: "Kirundi",
  },
  {
    value: "Konkani",
    label: "Konkani",
  },
  {
    value: "Korean",
    label: "Korean",
  },
  {
    value: "Kurdish",
    label: "Kurdish",
  },
  {
    value: "Madurese",
    label: "Madurese",
  },
  {
    value: "Magahi",
    label: "Magahi",
  },
  {
    value: "Maithili",
    label: "Maithili",
  },
  {
    value: "Malagasy",
    label: "Malagasy",
  },
  {
    value: "Malay (inc. Indonesian and Malaysian)",
    label: "Malay (inc. Indonesian and Malaysian)",
  },
  {
    value: "Malayalam",
    label: "Malayalam",
  },
  {
    value: "Mandarin (entire branch)",
    label: "Mandarin (entire branch)",
  },
  {
    value: "Marathi",
    label: "Marathi",
  },
  {
    value: "Marwari",
    label: "Marwari",
  },
  {
    value: "Mossi",
    label: "Mossi",
  },
  {
    value: "Nepali",
    label: "Nepali",
  },
  {
    value: "Odia (Oriya)",
    label: "Odia (Oriya)",
  },
  {
    value: "Oromo",
    label: "Oromo",
  },
  {
    value: "Pashto",
    label: "Pashto",
  },
  {
    value: "Persian",
    label: "Persian",
  },
  {
    value: "Polish",
    label: "Polish",
  },
  {
    value: "Portuguese",
    label: "Portuguese",
  },
  {
    value: "Punjabi",
    label: "Punjabi",
  },
  {
    value: "Quechua",
    label: "Quechua",
  },
  {
    value: "Romanian",
    label: "Romanian",
  },
  {
    value: "Russian",
    label: "Russian",
  },
  {
    value: "Saraiki",
    label: "Saraiki",
  },
  { value: "Scots", label: "Scots" },
  {
    value: "Serbo-Croatian",
    label: "Serbo-Croatian",
  },
  {
    value: "Shona",
    label: "Shona",
  },
  {
    value: "Sindhi",
    label: "Sindhi",
  },
  {
    value: "Sinhala",
    label: "Sinhala",
  },
  {
    value: "Somali",
    label: "Somali",
  },
  {
    value: "Southern Min (inc. Hokkien and Teochew)",
    label: "Southern Min (inc. Hokkien and Teochew)",
  },
  {
    value: "Spanish",
    label: "Spanish",
  },
  {
    value: "Sundanese",
    label: "Sundanese",
  },
  {
    value: "Swedish",
    label: "Swedish",
  },
  {
    value: "Sylheti",
    label: "Sylheti",
  },
  {
    value: "Tagalog (Filipino)",
    label: "Tagalog (Filipino)",
  },
  {
    value: "Tamil",
    label: "Tamil",
  },
  {
    value: "Telugu",
    label: "Telugu",
  },
  {
    value: "Thai",
    label: "Thai",
  },
  {
    value: "Turkish",
    label: "Turkish",
  },
  {
    value: "Turkmen",
    label: "Turkmen",
  },
  {
    value: "Ukrainian",
    label: "Ukrainian",
  },
  {
    value: "Urdu",
    label: "Urdu",
  },
  {
    value: "Uyghur",
    label: "Uyghur",
  },
  {
    value: "Uzbek",
    label: "Uzbek",
  },
  {
    value: "Vietnamese",
    label: "Vietnamese",
  },
  {
    value: "Wu (inc. Shanghainese)",
    label: "Wu (inc. Shanghainese)",
  },
  {
    value: "Xhosa",
    label: "Xhosa",
  },
  {
    value: "Xiang",
    label: "Xiang",
  },
  {
    value: "Yoruba",
    label: "Yoruba",
  },
  {
    value: "Yue (inc. Cantonese)",
    label: "Yue (inc. Cantonese)",
  },
  {
    value: "Zhuang",
    label: "Zhuang",
  },
  {
    value: "Zulu",
    label: "Zulu",
  },
];

export const raceList = [
  {
    value: "American Indian",
    label: "American Indian",
  },
  {
    value: "Arab",
    label: "Arab",
  },
  {
    value: "Black/Negroid",
    label: "Black/Negroid",
  },
  {
    value: "East Asian",
    label: "East Asian",
  },
  {
    value: "Hispanic / Latino",
    label: "Hispanic / Latino",
  },
  {
    value: "Mongoloid",
    label: "Mongoloid",
  },
  {
    value: "Other",
    label: "Other",
  },
  {
    value: "South Asian",
    label: "South Asian",
  },
  {
    value: "White/Caucasian",
    label: "White/Caucasian",
  },
];

export const religionList = [
  {
    value: "Atheism",
    label: "Atheism",
  },
  {
    value: "Atheist",
    label: "Atheist",
  },
  {
    value: "Bahai",
    label: "Bahai",
  },
  {
    value: "Buddhism",
    label: "Buddhism",
  },
  {
    value: "Christianity",
    label: "Christianity",
  },
  {
    value: "Confucianism",
    label: "Confucianism",
  },
  {
    value: "Hinduism",
    label: "Hinduism",
  },
  {
    value: "Islam",
    label: "Islam",
  },
  {
    value: "Jainism",
    label: "Jainism",
  },
  {
    value: "Judaism",
    label: "Judaism",
  },
  {
    value: "Other",
    label: "Other",
  },
  {
    value: "Shinto",
    label: "Shinto",
  },
  {
    value: "Sikhism",
    label: "Sikhism",
  },
  {
    value: "Taoism",
    label: "Taoism",
  },
  {
    value: "Tenriism",
    label: "Tenriism",
  },
  {
    value: "Vodu",
    label: "Vodu",
  },
  {
    value: "Zoroastrianism",
    label: "Zoroastrianism",
  },
];

export const starList = [
  {
    value: "Anurada",
    label: "Anurada",
  },
  {
    value: "Ardra",
    label: "Ardra",
  },
  {
    value: "Ashlesha",
    label: "Ashlesha",
  },
  {
    value: "Ashwini",
    label: "Ashwini",
  },
  {
    value: "Bharani",
    label: "Bharani",
  },
  {
    value: "Chitra",
    label: "Chitra",
  },
  {
    value: "Dhanishta",
    label: "Dhanishta",
  },
  {
    value: "Hasta",
    label: "Hasta",
  },
  {
    value: "Jyeshta",
    label: "Jyeshta",
  },
  {
    value: "Krittika",
    label: "Krittika",
  },
  {
    value: "Magha",
    label: "Magha",
  },
  {
    value: "Mrigashira",
    label: "Mrigashira",
  },
  {
    value: "Mula",
    label: "Mula",
  },
  {
    value: "Punarvasu",
    label: "Punarvasu",
  },
  {
    value: "Purva Ashadha",
    label: "Purva Ashadha",
  },
  {
    value: "Purva Bhadrapada",
    label: "Purva Bhadrapada",
  },
  {
    value: "Purva Phalguni",
    label: "Purva Phalguni",
  },
  {
    value: "Pushya",
    label: "Pushya",
  },
  {
    value: "Revati",
    label: "Revati",
  },
  {
    value: "Rohini",
    label: "Rohini",
  },
  {
    value: "Shatabhishak",
    label: "Shatabhishak",
  },
  {
    value: "Shravana",
    label: "Shravana",
  },
  {
    value: "Swati",
    label: "Swati",
  },
  {
    value: "Uttara Ashadha",
    label: "Uttara Ashadha",
  },
  {
    value: "Uttara Bhadrapada",
    label: "Uttara Bhadrapada",
  },
  {
    value: "Uttara Phalguni",
    label: "Uttara Phalguni",
  },
  {
    value: "Vishaka",
    label: "Vishaka",
  },
];

export const causeOfDeath = [
  {
    value: "Natural - Old Age",
    label: "Natural - Old Age",
  },
  {
    value: "Natural - Chronic Illness",
    label: "Natural - Chronic Illness",
  },
  {
    value: "Natural - Sudden Illness",
    label: "Natural - Sudden Illness",
  },
  {
    value: "Natural - Other",
    label: "Natural - Other",
  },
  {
    value: "Accident - Travel",
    label: "Accident - Travel",
  },
  {
    value: "Accident - Workplace",
    label: "Accident - Workplace",
  },
  {
    value: "Accident - Home",
    label: "Accident - Home",
  },
  {
    value: "Accident - Other",
    label: "Accident - Other",
  },
  {
    value: "Accident - Adventure",
    label: "Accident - Adventure",
  },
  {
    value: "In Line of Duty (Police, Army etc.)",
    label: "In Line of Duty (Police, Army etc.)",
  },
  {
    value: "Suicide",
    label: "Suicide",
  },
  {
    value: "Homicide",
    label: "Homicide",
  },
  {
    value: "Unknown - Missing",
    label: "Unknown - Missing",
  },
  {
    value: "Unknown - Other",
    label: "Unknown - Other",
  },
];

export const bestQualitiesList = [
  {
    value: "dere",
    label: "Acceptance",
  },
  {
    value: "Assertiveness",
    label: "Assertiveness",
  },
  {
    value: "Authenticity",
    label: "Authenticity",
  },
  {
    value: "Beauty",
    label: "Beauty",
  },
  {
    value: "Caring",
    label: "Caring",
  },
  {
    value: "Cleanliness",
    label: "Cleanliness",
  },
  {
    value: "Commitment",
    label: "Commitment",
  },
  {
    value: "Compassion",
    label: "Compassion",
  },
  {
    value: "Confidence",
    label: "Confidence",
  },
  {
    value: "Consideration",
    label: "Consideration",
  },
  {
    value: "Contentment",
    label: "Contentment",
  },
  {
    value: "Cooperation",
    label: "Cooperation",
  },
  {
    value: "Courage",
    label: "Courage",
  },
  {
    value: "Creativity",
    label: "Creativity",
  },
  {
    value: "Detachment",
    label: "Detachment",
  },
  {
    value: "Determination",
    label: "Determination",
  },
  {
    value: "Dignity",
    label: "Dignity",
  },
  {
    value: "Encouragement",
    label: "Encouragement",
  },
  {
    value: "Enthusiasm",
    label: "Enthusiasm",
  },
  {
    value: "Ethical",
    label: "Ethical",
  },
  {
    value: "Excellence",
    label: "Excellence",
  },
  {
    value: "Fairness",
    label: "Fairness",
  },
  {
    value: "Faith",
    label: "Faith",
  },
  {
    value: "Flexibility",
    label: "Flexibility",
  },
  {
    value: "Forgiveness",
    label: "Forgiveness",
  },
  {
    value: "Friendliness",
    label: "Friendliness",
  },
  {
    value: "Generosity",
    label: "Generosity",
  },
  {
    value: "Gentleness",
    label: "Gentleness",
  },
  {
    value: "Graciousness",
    label: "Graciousness",
  },
  {
    value: "Gratitude",
    label: "Gratitude",
  },
  {
    value: "Harmonious",
    label: "Harmonious",
  },
  {
    value: "Helpfulness",
    label: "Helpfulness",
  },
  {
    value: "Honesty",
    label: "Honesty",
  },
  {
    value: "Honor",
    label: "Honor",
  },
  {
    value: "Hope",
    label: "Hope",
  },
  {
    value: "Humility",
    label: "Humility",
  },
  {
    value: "Idealism",
    label: "Idealism",
  },
  {
    value: "Integrity",
    label: "Integrity",
  },
  {
    value: "Imaginative",
    label: "Imaginative",
  },
  {
    value: "Joyfulness",
    label: "Joyfulness",
  },
  {
    value: "Justice",
    label: "Justice",
  },
  {
    value: "Kindness",
    label: "Kindness",
  },
  {
    value: "Love",
    label: "Love",
  },
  {
    value: "Loyalty",
    label: "Loyalty",
  },
  {
    value: "Moderation",
    label: "Moderation",
  },
  {
    value: "Modesty",
    label: "Modesty",
  },
  {
    value: "Optimistic",
    label: "Optimistic",
  },
  {
    value: "Orderliness",
    label: "Orderliness",
  },
  {
    value: "Passionate",
    label: "Passionate",
  },
  {
    value: "Patience",
    label: "Patience",
  },
  {
    value: "Peace",
    label: "Peace",
  },
  {
    value: "Perseverance",
    label: "Perseverance",
  },
  {
    value: "Preparedness",
    label: "Preparedness",
  },
  {
    value: "Purposefulness",
    label: "Purposefulness",
  },
  {
    value: "Reliability",
    label: "Reliability",
  },
  {
    value: "Respect",
    label: "Respect",
  },
  {
    value: "Responsibility",
    label: "Responsibility",
  },
  {
    value: "Reverence",
    label: "Reverence",
  },
  {
    value: "Self-discipline",
    label: "Self-discipline",
  },
  {
    value: "Service",
    label: "Service",
  },
  {
    value: "Sincerity",
    label: "Sincerity",
  },
  {
    value: "Tact",
    label: "Tact",
  },
  {
    value: "Temperate",
    label: "Temperate",
  },
  {
    value: "Tenacious",
    label: "Tenacious",
  },
  {
    value: "Thankfulness",
    label: "Thankfulness",
  },
  {
    value: "Tolerance",
    label: "Tolerance",
  },
  {
    value: "Trust",
    label: "Trust",
  },
  {
    value: "Truthfulness",
    label: "Truthfulness",
  },
  {
    value: "Understanding",
    label: "Understanding",
  },
  {
    value: "Unity",
    label: "Unity",
  },
  {
    value: "Visionary",
    label: "Visionary",
  },
  {
    value: "Wisdom",
    label: "Wisdom",
  },
  {
    value: "Wonder",
    label: "Wonder",
  },
];

export const worstQualitiesList = [
  {
    value: "Anger",
    label: "Anger",
  },
  {
    value: "Arrogance",
    label: "Arrogance",
  },
  {
    value: "Bossy",
    label: "Bossy",
  },
  {
    value: "Bragging",
    label: "Bragging",
  },
  {
    value: "Control freak",
    label: "Control freak",
  },
  {
    value: "Cowardice",
    label: "Cowardice",
  },
  {
    value: "Disloyalty",
    label: "Disloyalty",
  },
  {
    value: "Doubt",
    label: "Doubt",
  },
  {
    value: "Egoism",
    label: "Egoism",
  },
  {
    value: "Envy",
    label: "Envy",
  },
  {
    value: "Gambling",
    label: "Gambling",
  },
  {
    value: "Greed",
    label: "Greed",
  },
  {
    value: "Impatience",
    label: "Impatience",
  },
  {
    value: "Impulsive",
    label: "Impulsive",
  },
  {
    value: "Indecision",
    label: "Indecision",
  },
  {
    value: "Indiscipline",
    label: "Indiscipline",
  },
  {
    value: "Inflexibility",
    label: "Inflexibility",
  },
  {
    value: "Injustice",
    label: "Injustice",
  },
  {
    value: "Jealousy",
    label: "Jealousy",
  },
  {
    value: "Lack of confidence",
    label: "Lack of confidence",
  },
  {
    value: "Laziness",
    label: "Laziness",
  },
  {
    value: "Moodiness",
    label: "Moodiness",
  },
  {
    value: "Negativity / Pessimism",
    label: "Negativity / Pessimism",
  },
  {
    value: "Neglecting health",
    label: "Neglecting health",
  },
  {
    value: "Over perfection",
    label: "Over perfection",
  },
  {
    value: "Over working",
    label: "Over working",
  },
  {
    value: "Overspending",
    label: "Overspending",
  },
  {
    value: "Procrastination",
    label: "Procrastination",
  },
  {
    value: "Recklessness",
    label: "Recklessness",
  },
  {
    value: "Shopping Addiction",
    label: "Shopping Addiction",
  },
  {
    value: "Short temper",
    label: "Short temper",
  },
  {
    value: "Sloth",
    label: "Sloth",
  },
  {
    value: "Stingy",
    label: "Stingy",
  },
  {
    value: "Stubbornness",
    label: "Stubbornness",
  },
  {
    value: "Tactless",
    label: "Tactless",
  },
  {
    value: "Untrustworthiness",
    label: "Untrustworthiness",
  },
  {
    value: "Vanity",
    label: "Vanity",
  },
  {
    value: "Weakness",
    label: "Weakness",
  },
  {
    value: "Wrath",
    label: "Wrath",
  },
];

export const hobbiesList = [
  {
    value: "Astrology",
    label: "Astrology",
  },
  {
    value: "Astronomy",
    label: "Astronomy",
  },
  {
    value: "Bird watching",
    label: "Bird watching",
  },
  {
    value: "Card Games",
    label: "Card Games",
  },
  {
    value: "Coin collection",
    label: "Coin collection",
  },
  {
    value: "Cooking",
    label: "Cooking",
  },
  {
    value: "Creative Writing",
    label: "Creative Writing",
  },
  {
    value: "Cycling",
    label: "Cycling",
  },
  {
    value: "Dancing",
    label: "Dancing",
  },
  {
    value: "Drawing / Painting",
    label: "Drawing / Painting",
  },
  {
    value: "Gardening",
    label: "Gardening",
  },
  {
    value: "Hiking / Nature trails",
    label: "Hiking / Nature trails",
  },
  {
    value: "Listening to Music",
    label: "Listening to Music",
  },
  {
    value: "Long Drives",
    label: "Long Drives",
  },
  {
    value: "Martial Arts",
    label: "Martial Arts",
  },
  {
    value: "Meditation",
    label: "Meditation",
  },
  {
    value: "People watching",
    label: "People watching",
  },
  {
    value: "Playing Music",
    label: "Playing Music",
  },
  {
    value: "Playing Sports",
    label: "Playing Sports",
  },
  {
    value: "Reading Books",
    label: "Reading Books",
  },
  {
    value: "Religious activities",
    label: "Religious activities",
  },
  {
    value: "Sewing / Embroidery",
    label: "Sewing / Embroidery",
  },
  {
    value: "Singing",
    label: "Singing",
  },
  {
    value: "Stamp collection",
    label: "Stamp collection",
  },
  {
    value: "Video games",
    label: "Video games",
  },
  {
    value: "Watching Movies",
    label: "Watching Movies",
  },
  {
    value: "Watching Sports",
    label: "Watching Sports",
  },
  {
    value: "Watching TV",
    label: "Watching TV",
  },
  {
    value: "Window Shopping",
    label: "Window Shopping",
  },
];

export const sportsList = [
  {
    value: "Archery",
    label: "Archery",
  },
  {
    value: "Artistic",
    label: "Artistic",
  },
  {
    value: "Athletics",
    label: "Athletics",
  },
  {
    value: "Badminton",
    label: "Badminton",
  },
  {
    value: "Baseball",
    label: "Baseball",
  },
  {
    value: "Basketball",
    label: "Basketball",
  },
  {
    value: "Beach Volleyball",
    label: "Beach Volleyball",
  },
  {
    value: "Boxing",
    label: "Boxing",
  },
  {
    value: "Canoeing",
    label: "Canoeing",
  },
  {
    value: "Car Racing",
    label: "Car Racing",
  },
  {
    value: "Cricket",
    label: "Cricket",
  },
  {
    value: "Cycling",
    label: "Cycling",
  },
  {
    value: "Diving",
    label: "Diving",
  },
  {
    value: "Fencing",
    label: "Fencing",
  },
  {
    value: "Football",
    label: "Football",
  },
  {
    value: "Formula 1",
    label: "Formula 1",
  },
  {
    value: "Golf",
    label: "Golf",
  },
  {
    value: "Gymnastics",
    label: "Gymnastics",
  },
  {
    value: "Hockey",
    label: "Hockey",
  },
  {
    value: "Horse Riding",
    label: "Horse Riding",
  },
  {
    value: "Kabbadi",
    label: "Kabbadi",
  },
  {
    value: "Marathon",
    label: "Marathon",
  },
  {
    value: "Martial Arts",
    label: "Martial Arts",
  },
  {
    value: "Mountain Bike",
    label: "Mountain Bike",
  },
  {
    value: "Rowing",
    label: "Rowing",
  },
  {
    value: "Rugby",
    label: "Rugby",
  },
  {
    value: "Sailing",
    label: "Sailing",
  },
  {
    value: "Shooting",
    label: "Shooting",
  },
  {
    value: "Soccer",
    label: "Soccer",
  },
  {
    value: "Surfing",
    label: "Surfing",
  },
  {
    value: "Swimming",
    label: "Swimming",
  },
  {
    value: "Table Tennis",
    label: "Table Tennis",
  },
  {
    value: "Tennis",
    label: "Tennis",
  },
  {
    value: "Volleyball",
    label: "Volleyball",
  },
  {
    value: "Water Polo",
    label: "Water Polo",
  },
  {
    value: "Weightlifting",
    label: "Weightlifting",
  },
  {
    value: "Wrestling",
    label: "Wrestling",
  },
];

export const musicList = [
  {
    value: "Blues",
    label: "Blues",
  },
  {
    value: "Country",
    label: "Country",
  },
  {
    value: "Electronic",
    label: "Electronic",
  },
  {
    value: "Soft Rock",
    label: "Soft Rock",
  },
  {
    value: "Hard Rock",
    label: "Hard Rock",
  },
  {
    value: "Pop",
    label: "Pop",
  },
  {
    value: "Indian Film",
    label: "Indian Film",
  },
  {
    value: "Hip Hop",
    label: "Hip Hop",
  },
  {
    value: "Folk",
    label: "Folk",
  },
  {
    value: "Jazz",
    label: "Jazz",
  },
  {
    value: "R&B / Soul",
    label: "R&B / Soul",
  },
  {
    value: "Metal",
    label: "Metal",
  },
  {
    value: "Punk",
    label: "Punk",
  },
  {
    value: "Bhajan",
    label: "Bhajan",
  },
];

export const educationLevelList = [
  {
    value: "None",
    label: "None",
  },
  {
    value: "Secondary school",
    label: "Secondary school",
  },
  {
    value: "High School",
    label: "High School",
  },
  {
    value: "Graduate",
    label: "Graduate",
  },
  {
    value: "Post graduate",
    label: "Post graduate",
  },
  {
    value: "Doctorate",
    label: "Doctorate",
  },
];

export const educationQualificationList = [
  {
    value: "Bachelor of Accountancy (BAcc)",
    label: "Bachelor of Accountancy (BAcc)",
  },
  {
    value: "Bachelor of Architecture (BArch)",
    label: "Bachelor of Architecture (BArch)",
  },
  {
    value: "Bachelor of Business Administration (BBA)",
    label: "Bachelor of Business Administration (BBA)",
  },
  {
    value: "Bachelor of Commerce (BComm)",
    label: "Bachelor of Commerce (BComm)",
  },
  {
    value: "Bachelor of Computer Science (BCS)",
    label: "Bachelor of Computer Science (BCS)",
  },
  {
    value: "Bachelor of Computer Application (BCA)",
    label: "Bachelor of Computer Application (BCA)",
  },
  {
    value: "Bachelor of Civil Law (BCL)",
    label: "Bachelor of Civil Law (BCL)",
  },
  {
    value: "Bachelor of Divinity (BDiv)",
    label: "Bachelor of Divinity (BDiv)",
  },
  {
    value: "Bachelor of Economics (BEc)",
    label: "Bachelor of Economics (BEc)",
  },
  {
    value: "Bachelor of Education (BEd)",
    label: "Bachelor of Education (BEd)",
  },
  {
    value: "Bachelor of Engineering (BEng)",
    label: "Bachelor of Engineering (BEng)",
  },
  {
    value: "Bachelor of Fine Arts (BFA)",
    label: "Bachelor of Fine Arts (BFA)",
  },
  {
    value: "Bachelor of Laws (LLB)",
    label: "Bachelor of Laws (LLB)",
  },
  {
    value: "Bachelor of Letters (BLitt)",
    label: "Bachelor of Letters (BLitt)",
  },
  {
    value: "Bachelor of Music (BM)",
    label: "Bachelor of Music (BM)",
  },
  {
    value: "Bachelor of Pharmacy (BPharm)",
    label: "Bachelor of Pharmacy (BPharm)",
  },
  {
    value: "Bachelor of Philosophy (BPhil)",
    label: "Bachelor of Philosophy (BPhil)",
  },
  {
    value: "Bachelor of Science in Nursing (BSN)",
    label: "Bachelor of Science in Nursing (BSN)",
  },
  {
    value: "Bachelor of Social Work (BSW)",
    label: "Bachelor of Social Work (BSW)",
  },
  {
    value: "Bachelor of Technology (BTech)",
    label: "Bachelor of Technology (BTech)",
  },
  {
    value: "Bachelor of Theology (BTh)",
    label: "Bachelor of Theology (BTh)",
  },
  {
    value: "Bachelor of Medicine, Bachelor of Surgery (MBBS)",
    label: "Bachelor of Medicine, Bachelor of Surgery (MBBS)",
  },
  {
    value: "Doctor of Arts (DA)",
    label: "Doctor of Arts (DA)",
  },
  {
    value: "Doctor of Audiology (AuD)",
    label: "Doctor of Audiology (AuD)",
  },
  {
    value: "Doctor of Business Administration (DBA)",
    label: "Doctor of Business Administration (DBA)",
  },
  {
    value: "Doctor of Canon Law (JCD)",
    label: "Doctor of Canon Law (JCD)",
  },
  {
    value: "Doctor of Civil Law (DCL)",
    label: "Doctor of Civil Law (DCL)",
  },
  {
    value: "Doctor of Clinical Psychology (DClinPsy)",
    label: "Doctor of Clinical Psychology (DClinPsy)",
  },
  {
    value: "Doctor of Chiropractic (DC)",
    label: "Doctor of Chiropractic (DC)",
  },
  {
    value: "Doctor of Dental Surgery (DDS)",
    label: "Doctor of Dental Surgery (DDS)",
  },
  {
    value: "Doctor of Divinity (DDiv)",
    label: "Doctor of Divinity (DDiv)",
  },
  {
    value: "Doctor of Education (EdD)",
    label: "Doctor of Education (EdD)",
  },
  {
    value: "Doctor of Juridical Science (JSD)",
    label: "Doctor of Juridical Science (JSD)",
  },
  {
    value: "Doctor of Letters (DLitt)",
    label: "Doctor of Letters (DLitt)",
  },
  {
    value: "Doctor of Medicine (MD)",
    label: "Doctor of Medicine (MD)",
  },
  {
    value: "Doctor of Ministry (DMin)",
    label: "Doctor of Ministry (DMin)",
  },
  {
    value: "Doctor of Naturopathic Medicine (ND)",
    label: "Doctor of Naturopathic Medicine (ND)",
  },
  {
    value: "Doctor of Osteopathic Medicine (DO)",
    label: "Doctor of Osteopathic Medicine (DO)",
  },
  {
    value: "Doctor of Pharmacy (DPharm)",
    label: "Doctor of Pharmacy (DPharm)",
  },
  {
    value: "Doctor of Philosophy (PhD)",
    label: "Doctor of Philosophy (PhD)",
  },
  {
    value: "Doctor of Psychology (PsyD)",
    label: "Doctor of Psychology (PsyD)",
  },
  {
    value: "Doctor of Science (DSc or ScD)",
    label: "Doctor of Science (DSc or ScD)",
  },
  {
    value: "Doctor of Theology (ThD)",
    label: "Doctor of Theology (ThD)",
  },
  {
    value: "Doctor of Veterinary Medicine (DVM)",
    label: "Doctor of Veterinary Medicine (DVM)",
  },
  {
    value: "Juris Doctor (JD)",
    label: "Juris Doctor (JD)",
  },
  {
    value: "Master of Accountancy",
    label: "Master of Accountancy",
  },
  {
    value: "Master of Advanced Study",
    label: "Master of Advanced Study",
  },
  {
    value: "Master of Agricultural Economics",
    label: "Master of Agricultural Economics",
  },
  {
    value: "Master of Applied Finance",
    label: "Master of Applied Finance",
  },
  {
    value: "Master of Applied Science",
    label: "Master of Applied Science",
  },
  {
    value: "Master of Architecture",
    label: "Master of Architecture",
  },
  {
    value: "Master of Arts",
    label: "Master of Arts",
  },
  {
    value: "Master of Bioethics",
    label: "Master of Bioethics",
  },
  {
    value: "Master of Business Administration",
    label: "Master of Business Administration",
  },
  {
    value: "Master of Business, Entrepreneurship and Technology",
    label: "Master of Business, Entrepreneurship and Technology",
  },
  {
    value: "Master of Business",
    label: "Master of Business",
  },
  {
    value: "Master of Business Engineering",
    label: "Master of Business Engineering",
  },
  {
    value: "Master of Business Informatics",
    label: "Master of Business Informatics",
  },
  {
    value: "Master of Chemistry",
    label: "Master of Chemistry",
  },
  {
    value: "Master of Christian Education",
    label: "Master of Christian Education",
  },
  {
    value: "Master of City Planning",
    label: "Master of City Planning",
  },
  {
    value: "Master of Commerce",
    label: "Master of Commerce",
  },
  {
    value: "Master of Computational Finance",
    label: "Master of Computational Finance",
  },
  {
    value: "Master of Computer Applications",
    label: "Master of Computer Applications",
  },
  {
    value: "Master of Counselling",
    label: "Master of Counselling",
  },
  {
    value: "Master of Criminal Justice",
    label: "Master of Criminal Justice",
  },
  {
    value: "Master of Creative Technologies",
    label: "Master of Creative Technologies",
  },
  {
    value: "Master of Data Science",
    label: "Master of Data Science",
  },
  {
    value: "Master of Defence Studies",
    label: "Master of Defence Studies",
  },
  {
    value: "Master of Design",
    label: "Master of Design",
  },
  {
    value: "Masters of Development Economics",
    label: "Masters of Development Economics",
  },
  {
    value: "Master of Divinity",
    label: "Master of Divinity",
  },
  {
    value: "Master of Economics",
    label: "Master of Economics",
  },
  {
    value: "Master of Education",
    label: "Master of Education",
  },
  {
    value: "Master of Engineering",
    label: "Master of Engineering",
  },
  {
    value: "Master of Enterprise",
    label: "Master of Enterprise",
  },
  {
    value: "Master of European Law",
    label: "Master of European Law",
  },
  {
    value: "Master of Finance",
    label: "Master of Finance",
  },
  {
    value: "Master of Fine Arts",
    label: "Master of Fine Arts",
  },
  {
    value: "Master of Health Administration",
    label: "Master of Health Administration",
  },
  {
    value: "Master of Health Economics",
    label: "Master of Health Economics",
  },
  {
    value: "Master of Health Science",
    label: "Master of Health Science",
  },
  {
    value: "Master of Humanities",
    label: "Master of Humanities",
  },
  {
    value: "Master of Industrial and Labor Relations",
    label: "Master of Industrial and Labor Relations",
  },
  {
    value: "Master of International Affairs",
    label: "Master of International Affairs",
  },
  {
    value: "Master of International Business",
    label: "Master of International Business",
  },
  {
    value: "Master of International Economics",
    label: "Master of International Economics",
  },
  {
    value: "Master of International Studies",
    label: "Master of International Studies",
  },
  {
    value: "Master of Information and Cybersecurity",
    label: "Master of Information and Cybersecurity",
  },
  {
    value: "Master of Information and Data Science",
    label: "Master of Information and Data Science",
  },
  {
    value: "Master of Information Management",
    label: "Master of Information Management",
  },
  {
    value: "Master of Information System Management",
    label: "Master of Information System Management",
  },
  {
    value: "Master of Journalism",
    label: "Master of Journalism",
  },
  {
    value: "Master of Jurisprudence",
    label: "Master of Jurisprudence",
  },
  {
    value: "Master of Laws",
    label: "Master of Laws",
  },
  {
    value: "Master of Mass Communication",
    label: "Master of Mass Communication",
  },
  {
    value: "Master of Studies in Law",
    label: "Master of Studies in Law",
  },
  {
    value: "Master of Landscape Architecture",
    label: "Master of Landscape Architecture",
  },
  {
    value: "Master of Letters",
    label: "Master of Letters",
  },
  {
    value: "Master of Liberal Arts",
    label: "Master of Liberal Arts",
  },
  {
    value: "Master of Library and Information Science",
    label: "Master of Library and Information Science",
  },
  {
    value: "Master of Management",
    label: "Master of Management",
  },
  {
    value: "Master of Management of Innovation",
    label: "Master of Management of Innovation",
  },
  {
    value: "Master of Marketing Research",
    label: "Master of Marketing Research",
  },
  {
    value: "Master of Mathematical Finance",
    label: "Master of Mathematical Finance",
  },
  {
    value: "Master of Mathematics",
    label: "Master of Mathematics",
  },
  {
    value: "Master of Medical Science",
    label: "Master of Medical Science",
  },
  {
    value: "Master of Medicine",
    label: "Master of Medicine",
  },
  {
    value: "Master of Military Art and Science",
    label: "Master of Military Art and Science",
  },
  {
    value: "Master of Military Operational Art and Science",
    label: "Master of Military Operational Art and Science",
  },
  {
    value: "Master of Ministry",
    label: "Master of Ministry",
  },
  {
    value: "Master of Music",
    label: "Master of Music",
  },
  {
    value: "Master of Music Education",
    label: "Master of Music Education",
  },
  {
    value: "Master of Occupational Behaviour and Development",
    label: "Master of Occupational Behaviour and Development",
  },
  {
    value: "Master of Occupational Therapy",
    label: "Master of Occupational Therapy",
  },
  {
    value: "Master of Pharmacy",
    label: "Master of Pharmacy",
  },
  {
    value: "Master of Philosophy",
    label: "Master of Philosophy",
  },
  {
    value: "Master of Physician Assistant Studies",
    label: "Master of Physician Assistant Studies",
  },
  {
    value: "Master of Physics",
    label: "Master of Physics",
  },
  {
    value: "Master of Political Science",
    label: "Master of Political Science",
  },
  {
    value: "Master of Professional Studies",
    label: "Master of Professional Studies",
  },
  {
    value: "Master of Psychology",
    label: "Master of Psychology",
  },
  {
    value: "Master of Public Administration",
    label: "Master of Public Administration",
  },
  {
    value: "Master of Public Affairs",
    label: "Master of Public Affairs",
  },
  {
    value: "Master of Public Health",
    label: "Master of Public Health",
  },
  {
    value: "Master of Public Management",
    label: "Master of Public Management",
  },
  {
    value: "Master of Public Policy",
    label: "Master of Public Policy",
  },
  {
    value: "Master of Public Relations",
    label: "Master of Public Relations",
  },
  {
    value: "Master of Public Service",
    label: "Master of Public Service",
  },
  {
    value: "Master of Quantitative Finance",
    label: "Master of Quantitative Finance",
  },
  {
    value: "Master of Rabbinic Studies",
    label: "Master of Rabbinic Studies",
  },
  {
    value: "Master of Real Estate Development",
    label: "Master of Real Estate Development",
  },
  {
    value: "Master of Religious Education",
    label: "Master of Religious Education",
  },
  {
    value: "Master of Research",
    label: "Master of Research",
  },
  {
    value: "Master of Sacred Music",
    label: "Master of Sacred Music",
  },
  {
    value: "Master of Sacred Theology",
    label: "Master of Sacred Theology",
  },
  {
    value: "Master of Science",
    label: "Master of Science",
  },
  {
    value: "Master of Social Science",
    label: "Master of Social Science",
  },
  {
    value: "Master of Social Work",
    label: "Master of Social Work",
  },
  {
    value: "Master of Strategic Studies",
    label: "Master of Strategic Studies",
  },
  {
    value: "Master of Studies",
    label: "Master of Studies",
  },
  {
    value: "Master of Surgery",
    label: "Master of Surgery",
  },
  {
    value: "Master of Talmudic Law",
    label: "Master of Talmudic Law",
  },
  {
    value: "Master of Taxation",
    label: "Master of Taxation",
  },
  {
    value: "Master of Theological Studies",
    label: "Master of Theological Studies",
  },
  {
    value: "Master of Technology",
    label: "Master of Technology",
  },
  {
    value: "Master of Technology Management",
    label: "Master of Technology Management",
  },
  {
    value: "Master of Theology",
    label: "Master of Theology",
  },
  {
    value: "Master of Urban Planning",
    label: "Master of Urban Planning",
  },
  {
    value: "Master of Veterinary Science",
    label: "Master of Veterinary Science",
  },
];

export const educationAreaList = [
  {
    value: "Anthropology",
    label: "Anthropology",
  },
  {
    value: "Archaeology",
    label: "Archaeology",
  },
  {
    value: "History",
    label: "History",
  },
  {
    value: "Linguistics and languages",
    label: "Linguistics and languages",
  },
  {
    value: "Philosophy",
    label: "Philosophy",
  },
  {
    value: "Religion",
    label: "Religion",
  },
  {
    value: "Culinary arts",
    label: "Culinary arts",
  },
  {
    value: "Literature",
    label: "Literature",
  },
  {
    value: "Performing arts",
    label: "Performing arts",
  },
  {
    value: "Visual arts",
    label: "Visual arts",
  },
  {
    value: "Economics",
    label: "Economics",
  },
  {
    value: "Geography",
    label: "Geography",
  },
  {
    value: "Region/Area Studies",
    label: "Region/Area Studies",
  },
  {
    value: "Ethnic and cultural studies",
    label: "Ethnic and cultural studies",
  },
  {
    value: "Gender and sexuality studies",
    label: "Gender and sexuality studies",
  },
  {
    value: "Organizational studies",
    label: "Organizational studies",
  },
  {
    value: "Political science",
    label: "Political science",
  },
  {
    value: "Psychology",
    label: "Psychology",
  },
  {
    value: "Sociology",
    label: "Sociology",
  },
  {
    value: "Biology",
    label: "Biology",
  },
  {
    value: "Chemistry",
    label: "Chemistry",
  },
  {
    value: "Earth sciences",
    label: "Earth sciences",
  },
  {
    value: "Physics",
    label: "Physics",
  },
  {
    value: "Space sciences",
    label: "Space sciences",
  },
  {
    value: "Astronomy",
    label: "Astronomy",
  },
  {
    value: "Computer sciences",
    label: "Computer sciences",
  },
  {
    value: "Logic",
    label: "Logic",
  },
  {
    value: "Pure mathematics",
    label: "Pure mathematics",
  },
  {
    value: "Applied mathematics",
    label: "Applied mathematics",
  },
  {
    value: "Statistics",
    label: "Statistics",
  },
  {
    value: "Systems science",
    label: "Systems science",
  },
  {
    value: "Agriculture",
    label: "Agriculture",
  },
  {
    value: "Architecture and design",
    label: "Architecture and design",
  },
  {
    value: "Business",
    label: "Business",
  },
  {
    value: "Divinity",
    label: "Divinity",
  },
  {
    value: "Education",
    label: "Education",
  },
  {
    value: "Engineering and technology",
    label: "Engineering and technology",
  },
  {
    value: "Environmental studies and forestry",
    label: "Environmental studies and forestry",
  },
  {
    value: "Family and consumer science",
    label: "Family and consumer science",
  },
  {
    value: "Human physical performance and recreation",
    label: "Human physical performance and recreation",
  },
  {
    value: "Journalism, media studies and communication",
    label: "Journalism, media studies and communication",
  },
  {
    value: "Law",
    label: "Law",
  },
  {
    value: "Library and museum studies",
    label: "Library and museum studies",
  },
  {
    value: "Medicine",
    label: "Medicine",
  },
  {
    value: "Military sciences",
    label: "Military sciences",
  },
  {
    value: "Public administration",
    label: "Public administration",
  },
  {
    value: "Public policy",
    label: "Public policy",
  },
  {
    value: "Social work",
    label: "Social work",
  },
  {
    value: "Transportation",
    label: "Transportation",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const occupationList = [
  {
    value: "Agriculture",
    label: "Agriculture",
  },
  {
    value: "Horticulture",
    label: "Horticulture",
  },
  {
    value: "Civil Engineering",
    label: "Civil Engineering",
  },
  {
    value: "Cooking",
    label: "Cooking",
  },
  {
    value: "Hotel Management",
    label: "Hotel Management",
  },
  {
    value: "Nursing",
    label: "Nursing",
  },
  {
    value: "Health service",
    label: "Health service",
  },
  {
    value: "Teaching / Coaching",
    label: "Teaching / Coaching",
  },
  {
    value: "Aquaculture",
    label: "Aquaculture",
  },
  {
    value: "Foundry / Iron Smithing",
    label: "Foundry / Iron Smithing",
  },
  {
    value: "Goldsmithy",
    label: "Goldsmithy",
  },
  {
    value: "Music Composition",
    label: "Music Composition",
  },
  {
    value: "Carpentry",
    label: "Carpentry",
  },
  {
    value: "Singing",
    label: "Singing",
  },
  {
    value: "Automobile Engineering",
    label: "Automobile Engineering",
  },
  {
    value: "Banking",
    label: "Banking",
  },
];

export const bloodGroupList = [
  {
    value: "A+",
    label: "A+",
  },
  {
    value: "B+",
    label: "B+",
  },
  {
    value: "AB+",
    label: "AB+",
  },
  {
    value: "O+",
    label: "O+",
  },
  {
    value: "A-",
    label: "A-",
  },
  {
    value: "B-",
    label: "B-",
  },
  {
    value: "AB-",
    label: "AB-",
  },
  {
    value: "O-",
    label: "O-",
  },
];

export const physicalActivityList = [
  {
    value: "Low",
    label: "Low",
  },
  {
    value: "Medium",
    label: "Medium",
  },
  {
    value: "High",
    label: "High",
  },
];

export const chronicIllnessList = [
  {
    value: "Diabetes (Sugar)",
    label: "Diabetes (Sugar)",
  },
  {
    value: "Constipation",
    label: "Constipation",
  },
  {
    value: "Hypertension (High BP)",
    label: "Hypertension (High BP)",
  },
  {
    value: "Hypotension (Low BP)",
    label: "Hypotension (Low BP)",
  },
  {
    value: "Heart Disease",
    label: "Heart Disease",
  },
  {
    value: "Kidney Disease",
    label: "Kidney Disease",
  },
  {
    value: "Obesity",
    label: "Obesity",
  },
  {
    value: "Arthritis",
    label: "Arthritis",
  },
  {
    value: "Asthma",
    label: "Asthma",
  },
  {
    value: "Cystic Fibrosis",
    label: "Cystic Fibrosis",
  },
  {
    value: "Osteoporosis",
    label: "Osteoporosis",
  },
  {
    value: "Depression",
    label: "Depression",
  },
  {
    value: "Autism",
    label: "Autism",
  },
  {
    value: "Dementia",
    label: "Dementia",
  },
  {
    value: "Cancer",
    label: "Cancer",
  },
  {
    value: "Thalessemia",
    label: "Thalessemia",
  },
  {
    value: "Hyperlipidemia (High cholesterol)",
    label: "Hyperlipidemia (High cholesterol)",
  },
  {
    value: "Sleep Apnea",
    label: "Sleep Apnea",
  },
  {
    value: "Pulmonary disease",
    label: "Pulmonary disease",
  },
];

export const smokingHabitList = [
  {
    value: "Never",
    label: "Never",
  },
  {
    value: "Experimented",
    label: "Experimented",
  },
  {
    value: "Occasional",
    label: "Occasional",
  },
  {
    value: "Regular",
    label: "Regular",
  },
  {
    value: "Heavy",
    label: "Heavy",
  },
];

export const searchtabs = [
  { label: "member", value: "nameurl" },
  { label: "lifeEvents", value: "le" },
  { label: "frozenMoments", value: "mem" },
  { label: "achievements", value: "ach" },
  { label: "passDowns", value: "wisdom" },
  { label: "loveAndWishes", value: "wish" },
  { label: "announcements", value: "anc" },
  { label: "anecdotes", value: "anecdotes" },
];
export const eventTypes = [
  "le",
  "mem",
  "ach",
  "wish",
  "wisdom",
  "impress",
  "anc",
];
export const homeFeedEventTypes = [
  "le",
  "mem",
  "ach",
  "wish",
  "wisdom",
  "impress",
  "anc",
  "random",
];
export const wishTypeAlive = [
  {
    value: "birthday",
    label: "Birthday",
  },
  {
    value: "engagement",
    label: "Engagement",
  },
  {
    value: "marriage",
    label: "Marriage",
  },
  {
    value: "wedanniv",
    label: "Wedding Anniversary",
  },
  {
    value: "graduation",
    label: "Graduation",
  },
  {
    value: "babyshower",
    label: "Baby Showers",
  },
  {
    value: "getwell",
    label: "Get Well Soon",
  },
  {
    value: "condolence",
    label: "Condolence",
  },
  {
    value: "award",
    label: "Awards / Promotions",
  },
  {
    value: "retire",
    label: "Retirement",
  },
  {
    value: "newhouse",
    label: "House Warming",
  },
  {
    value: "festivals",
    label: "Festivals",
  },
  {
    value: "newyear",
    label: "New Year",
  },
  {
    value: "random",
    label: "Just like that",
  },
  {
    value: "other",
    label: "Other",
  },
];
export const wishTypeDead = [
  {
    value: "Remembrance",
    label: "remembrance",
  },
  {
    value: "Just like that",
    label: "random",
  },
  {
    value: "Other",
    label: "other",
  },
];

import React, { useState } from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { t } from "i18next";
import { useForm, Controller } from "react-hook-form";
import { HelperIcon } from "../atoms/HelperIcon";
import {
  causeOfDeath,
  countriesList,
  languageList,
  raceList,
  religionList,
  starList,
  visibilityTypes,
} from "../../utils/ListItems";
import { VisiblityHelperText } from "../atoms/VisiblityHelperText";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { citiesApi } from "../../api/citiesApi";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useStyles } from "../../utils/Theme";
import {
  autoCompleteDropdownValidation,
  getLabelAndValue,
} from "../../utils/functions";
import LoadingButton from "@mui/lab/LoadingButton";
import { useEditLgAboutMutation } from "../../redux/slice/lgabout";

export const LgPersonal = ({
  open,
  close = () => {},
  mode,
  deceased,
  dob = null,
  birth_time = null,
  dod = null,
  birth_cntry = null,
  anc_region = null,
  nat_lang = null,
  religion = null,
  nation = null,
  last_res = null,
  dth_loc = null,
  death_cause = null,
  race = null,
  caste = null,
  clan = null,
  star = null,
  pers_add_details = null,
  mort_rem = null,
  mort_rem_loc = null,
  pers_visib = "dist",
  lg_id = "",
  lg_url = "",
  onNext = () => {},
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [noCitiesFound, setNoCitiesFound] = useState(false);
  const [lastResidence, setLastResidence] = useState([]);
  const defaultValues = {
    dob: dob,
    birth_time: birth_time,
    dod: dod,
    nat_lang: getLabelAndValue(nat_lang, languageList),
    religion: getLabelAndValue(religion, religionList),
    birth_cntry: birth_cntry,
    anc_region: anc_region,
    nation: getLabelAndValue(nation, countriesList),
    last_res: last_res,
    dth_loc: dth_loc,
    death_cause: getLabelAndValue(death_cause, causeOfDeath),
    race: getLabelAndValue(race, raceList),
    caste: caste,
    clan: clan,
    star: getLabelAndValue(star, starList),
    pers_add_details: pers_add_details,
    mort_rem: mort_rem,
    mort_rem_loc: mort_rem_loc,
    pers_visib: pers_visib === null ? "dist" : pers_visib,
    lg_id: lg_id,
    url: "lgPersonal",
    lg_url: lg_url,
  };
  const { handleSubmit, control, formState } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const [editLg, { isLoading }] = useEditLgAboutMutation();
  let lastResidenceTimer;
  const classes = useStyles();

  //-----------------------Declare Functions Here-----------------------------//
  dayjs.extend(utc);
  const getLastResidenceCities = async (value) => {
    clearTimeout(lastResidenceTimer);
    lastResidenceTimer = setTimeout(async () => {
      let cities = await citiesApi(value);
      setLastResidence(cities);
    }, 600);
  };

  const formatData = (data) => {
    data.death_cause = data.death_cause?.value
      ? data.death_cause.value
      : data.death_cause;
    data.nat_lang = data.nat_lang?.value ? data.nat_lang.value : data.nat_lang;
    data.nation = data.nation?.value ? data.nation.value : data.nation;
    data.race = data.race?.value ? data.race.value : data.race;
    data.religion = data.religion?.value ? data.religion.value : data.religion;
    data.star = data.star?.value ? data.star.value : data.star;
    editLg(data)
      .then(() => {
        if (mode === "self" || mode === "new") {
          onNext();
        } else {
          close();
        }
      })
      .catch(() => close());
  };
  // do not delete this variable
  let dummy = {
    isValid: formState.isValid,
    isDirty: formState.isDirty,
  };

  return (
    <>
      <CustomDialog
        open={open}
        iconPlacement={mode === "self" ? "no" : "left"}
        close={close}
        title={
          <Typography
            variant="h6"
            sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
            align="center"
          >
            {t("display.text.text.editModeLgPersonalTitle")}
          </Typography>
        }
        action={
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <LoadingButton
              variant="contained"
              loading={isLoading}
              disabled={!(formState.isDirty && formState.isValid)}
              onClick={handleSubmit((data) => formatData(data))}
            >
              {t("display.text.button.save")}
            </LoadingButton>
          </Box>
        }
      >
        <Card sx={{ width: "100%", p: 1 }}>
          <Stack spacing={2}>
            {mode === "edit" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name={"dob"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DatePicker
                      {...field}
                      // onChange={() => {}}
                      onAccept={(e) => {
                        field.onChange(dayjs(e).utc(true).format());
                      }}
                      label={t("display.text.inputField.dob")}
                      maxDate={dayjs()}
                      inputFormat="dd/mm/yyyy"
                      value={field.value ? dayjs(field.value) : null}
                      renderInput={(params) => (
                        <TextField
                          sx={{ width: "100%" }}
                          {...params}
                          error={fieldState.error ? true : false}
                          required={true}
                          helperText={
                            fieldState.error
                              ? fieldState.error.message
                              : t("display.text.texts.useApproxDate")
                          }
                        />
                      )}
                    />
                  )}
                  rules={{
                    validate: (value) =>
                      new Date(value) <= new Date() ||
                      t("display.text.error.dateError"),
                  }}
                />
              </LocalizationProvider>
            )}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name={"birth_time"}
                control={control}
                render={({ field, fieldState }) => (
                  <MobileTimePicker
                    {...field}
                    onChange={() => {}}
                    onAccept={(e) => {
                      field.onChange(dayjs(e).format());
                    }}
                    label={t("display.text.inputField.birthTime")}
                    value={field.value ? dayjs(field.value) : null}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: "100%" }}
                        {...params}
                        error={fieldState.error ? true : false}
                        required={true}
                        helperText={
                          fieldState.error
                            ? fieldState.error.message
                            : t("display.text.text.useApproxDate")
                        }
                      />
                    )}
                  />
                )}
                rules={{
                  validate: (value) =>
                    new Date(value) <= new Date() ||
                    t("display.text.error.dateError"),
                }}
              />
            </LocalizationProvider>
            <Controller
              name={"birth_cntry"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  noOptionsText={
                    noCitiesFound
                      ? t("display.text.inputField.noCitiesFound")
                      : t("display.text.inputField.noOptionTextCities")
                  }
                  clearOnBlur
                  {...field}
                  value={field.value}
                  options={lastResidence}
                  autoHighlight={true}
                  freeSolo={false}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      classes={{
                        root: classes.textFieldRoot, // apply class here
                      }}
                      label={t("display.text.inputField.birthCountry")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            <HelperIcon
                              helperText={
                                <Typography variant="body1">
                                  {t("display.text.helperText.birthPlace")}
                                </Typography>
                              }
                            />
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => {
                    if (data.length > 5) setNoCitiesFound(true);
                    else setNoCitiesFound(false);
                    if (data.length > 3) {
                      getLastResidenceCities(data);
                    }
                  }}
                />
              )}
            />
            <Controller
              name={"anc_region"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  // clearOnBlur
                  {...field}
                  value={field.value}
                  options={lastResidence}
                  autoHighlight={true}
                  freeSolo={true}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={t("display.text.inputField.ancRegion")}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => {
                    if (data.length > 3) getLastResidenceCities(data);
                  }}
                />
              )}
            />
            <Controller
              name={"nation"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  value={field.value}
                  options={countriesList}
                  autoHighlight={true}
                  freeSolo={false}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={t("display.text.inputField.nationality")}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => field.onChange(data)}
                />
              )}
              rules={{
                validate: (value) =>
                  autoCompleteDropdownValidation(value, countriesList) ||
                  t("display.text.error.autoselectDropdownError"),
              }}
            />
            <Controller
              name={"nat_lang"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  value={field.value}
                  options={languageList.sort()}
                  autoHighlight={true}
                  freeSolo={false}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={t("display.text.inputField.nativeLanguage")}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => field.onChange(data)}
                />
              )}
              rules={{
                validate: (value) =>
                  autoCompleteDropdownValidation(value, languageList) ||
                  t("display.text.error.autoselectDropdownError"),
              }}
            />
            <Controller
              name={"race"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  value={field.value}
                  options={raceList.sort()}
                  autoHighlight={true}
                  freeSolo={false}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={t("display.text.inputField.race")}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => field.onChange(data)}
                />
              )}
              rules={{
                validate: (value) =>
                  autoCompleteDropdownValidation(value, raceList) ||
                  t("display.text.error.autoselectDropdownError"),
              }}
            />
            <Controller
              name={"religion"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  value={field.value}
                  options={religionList.sort()}
                  autoHighlight={true}
                  freeSolo={false}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={t("display.text.inputField.religion")}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => field.onChange(data)}
                />
              )}
              rules={{
                validate: (value) =>
                  autoCompleteDropdownValidation(value, religionList) ||
                  t("display.text.error.autoselectDropdownError"),
              }}
            />
            <Controller
              name={"caste"}
              control={control}
              defaultValue={""}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("display.text.inputField.caste")}
                  type={"text"}
                  inputProps={{
                    maxLength: 26,
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  sx={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <HelperIcon
                        helperText={
                          <Typography variant="body1">
                            {t("display.text.helperText.caste")}
                          </Typography>
                        }
                      />
                    ),
                  }}
                />
              )}
              rules={{
                maxLength: {
                  value: 25,
                  message: t("display.text.error.userNameValidationErr"),
                },
              }}
            />
            <Controller
              name={"clan"}
              control={control}
              defaultValue={""}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("display.text.inputField.clan")}
                  type={"text"}
                  inputProps={{
                    maxLength: 46,
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  sx={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <HelperIcon
                        helperText={
                          <Typography variant="body1">
                            {t("display.text.helperText.clan")}
                          </Typography>
                        }
                      />
                    ),
                  }}
                />
              )}
              rules={{
                maxLength: {
                  value: 45,
                  message: t("display.text.error.userNameValidationErr"),
                },
              }}
            />
            <Controller
              name={"star"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  value={field.value}
                  options={starList.sort()}
                  autoHighlight={true}
                  freeSolo={false}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        classes={{
                          root: classes.textFieldRoot, // apply class here
                        }}
                        label={t("display.text.inputField.star")}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                        sx={{ flex: 1 }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <HelperIcon
                                helperText={
                                  <Typography variant="body1">
                                    {t("display.text.helperText.star")}
                                  </Typography>
                                }
                              />
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => field.onChange(data)}
                />
              )}
              rules={{
                validate: (value) =>
                  autoCompleteDropdownValidation(value, starList) ||
                  t("display.text.error.autoselectDropdownError"),
              }}
            />
            <Controller
              name={"last_res"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  noOptionsText={t(
                    "display.text.inputField.noOptionTextCities"
                  )}
                  {...field}
                  value={field.value}
                  options={lastResidence}
                  autoHighlight={true}
                  freeSolo={false}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={
                          deceased === "Yes"
                            ? t("display.text.inputField.lastRes")
                            : t("display.text.inputField.currentRes")
                        }
                        error={fieldState.error ? true : false}
                        placeholder={t("display.text.inputField.EnterAcity")}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => {
                    if (data.length > 3) getLastResidenceCities(data);
                  }}
                />
              )}
            />
            {true && (
              // deceased === "Yes"
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={"dod"}
                    control={control}
                    render={({ field, fieldState }) => (
                      <DatePicker
                        {...field}
                        // onChange={() => {}}
                        onAccept={(e) => {
                          field.onChange(dayjs(e).utc(true).format());
                        }}
                        label={t("display.text.inputField.dod")}
                        maxDate={dayjs()}
                        inputFormat="dd/mm/yyyy"
                        value={field.value ? dayjs(field.value) : null}
                        renderInput={(params) => (
                          <TextField
                            sx={{ width: "100%" }}
                            {...params}
                            error={fieldState.error ? true : false}
                            required={true}
                            helperText={
                              fieldState.error
                                ? fieldState.error.message
                                : t("display.text.text.useApproxDate")
                            }
                          />
                        )}
                      />
                    )}
                    rules={{
                      validate: (value) =>
                        new Date(value) <= new Date() ||
                        t("display.text.error.dateError"),
                    }}
                  />
                </LocalizationProvider>
                <Controller
                  name={"death_cause"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      {...field}
                      value={field.value}
                      options={causeOfDeath.sort()}
                      autoHighlight={true}
                      freeSolo={false}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.label}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <Box>
                          <TextField
                            {...params}
                            label={t("display.text.inputField.CauseofDeath")}
                            error={fieldState.error ? true : false}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          />
                        </Box>
                      )}
                      onChange={(_, data) => field.onChange(data)}
                      onInputChange={(_, data) => field.onChange(data)}
                    />
                  )}
                  rules={{
                    validate: (value) =>
                      autoCompleteDropdownValidation(value, causeOfDeath) ||
                      t("display.text.error.autoselectDropdownError"),
                  }}
                />

                <Controller
                  name={"mort_rem_loc"}
                  control={control}
                  defaultValue={""}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      label={t("display.text.inputField.MortalRemainsLocation")}
                      type={"text"}
                      inputProps={{
                        maxLength: 201,
                      }}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                      sx={{ width: "100%" }}
                      InputProps={{
                        endAdornment: (
                          <HelperIcon
                            helperText={
                              <Typography variant="body1">
                                {t(
                                  "display.text.helperText.mortalRemainsLocation"
                                )}
                              </Typography>
                            }
                          />
                        ),
                      }}
                    />
                  )}
                  rules={{
                    maxLength: {
                      value: 200,
                      message: t("display.text.error.userNameValidationErr"),
                    },
                  }}
                />
              </>
            )}
            <Controller
              name={"pers_add_details"}
              control={control}
              defaultValue={defaultValues.pers_add_details}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("display.text.inputField.AdditionalDetails")}
                  type={"text"}
                  inputProps={{
                    maxLength: 1001,
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    endAdornment: (
                      <HelperIcon
                        helperText={
                          <Typography variant="body1">
                            {t("display.text.helperText.additionalDetails")}
                          </Typography>
                        }
                      />
                    ),
                  }}
                  sx={{ width: "100%" }}
                />
              )}
              rules={{
                maxLength: {
                  value: 1000,
                  message: t("display.text.error.maxCharacters", {
                    param1: 1000,
                  }),
                },
              }}
            />

            <Controller
              name={"pers_visib"}
              control={control}
              defaultValue={defaultValues.pers_visib}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  classes={{
                    root: classes.textFieldRoot, // apply class here
                  }}
                  select={true}
                  label={t("display.text.inputField.Visiblity")}
                  value={field.value}
                  required={true}
                  sx={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <HelperIcon helperText={<VisiblityHelperText />} />
                    ),
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  {visibilityTypes.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: {
                  value: true,
                  message: t("display.text.error.fieldIsRequired"),
                },
              }}
            />
          </Stack>
        </Card>
      </CustomDialog>
    </>
  );
};

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import Slide from "@mui/material/Slide";
import { updateHomeFeedNudgeTimer } from "../../redux/slice/authentication";
import { getTimeDifference } from "../../utils/functions";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import { welcomepromtImage } from "../../utils/ListItems";
import { updateCurrentPage } from "../../redux/slice/createLg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const HomeFeedWelcomPromt = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { nudgePost } = useSelector((state) => state.notification);
  const { mode, currentPage } = useSelector((state) => state.createLg);
  const { homeFeedNudgeTimer } = useSelector((state) => state.authentication);

  const [open, setOpen] = useState(
    mode === null &&
      currentPage === null &&
      (Number(nudgePost.nudgeAddRelations) === 1 ||
        Number(nudgePost.nudgeNewPost === 1) ||
        Number(nudgePost.nudgeProfileDetails === 1)) &&
      (homeFeedNudgeTimer
        ? getTimeDifference(new Date(), homeFeedNudgeTimer).days > 1
        : true)
  );

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(updateHomeFeedNudgeTimer(new Date().toISOString()));
    setOpen(false);
  };

  const handleNext = (value) => {
    if (value === 3 && mode !== "self") {
      setOpen(false);
    } else if (value === 3 && mode === "self") {
      dispatch(updateCurrentPage("about"));
      handleClose();
    }
  };
  const IntroCard = ({ value }) => {
    let cardStyles = {
      width: "100%",
      boxShadow: "0px 4px 4px 0px #00000040",
      backgroundColor: "#fff",
      pb: 6,
    };
    return (
      <Box
        sx={{
          backgroundColor: "#ffffff",
          borderRadius: 1,
          width: "100%",
          boxShadow: "0px 4px 4px 0px #00000040",
          position: "relative",
        }}
      >
        <Box sx={{ p: 1 }} className="swiper-button image-swiper-button-next">
          <Button variant="contained" onClick={() => handleNext(value)}>
            {mode !== "self"
              ? value !== 3
                ? t("display.text.button.next")
                : t("display.text.button.continueToFamscape")
              : value !== 3
              ? t("display.text.button.next")
              : t("display.text.button.createYourLg")}
          </Button>
        </Box>
        <Box
          sx={{ p: 1, display: value === 0 ? "none" : "block" }}
          className="swiper-button image-swiper-button-prev"
        >
          <Button variant="contained" color="secondary">
            {t("display.text.button.previous")}
          </Button>
        </Box>
        <Card sx={cardStyles}>
          <img
            src={welcomepromtImage[value]}
            style={{ width: "100%", maxHeight: "250px" }}
          />
          <Stack sx={{ p: 1 }} spacing={1}>
            {value === 0 && (
              <>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600, color: "#333333" }}
                >
                  {" "}
                  {t("display.text.text.introHeading")}
                </Typography>
                <Typography sx={{ fontSize: "16px", color: "#666666" }}>
                  {t("display.text.text.homeFeedPromtP1")}
                </Typography>
              </>
            )}
            {value === 1 && (
              <>
                <Typography sx={{ fontSize: "16px", color: "#666666" }}>
                  {t("display.text.text.homeFeedPromtP3")}
                </Typography>
              </>
            )}
            {value === 2 && (
              <>
                <Typography sx={{ fontSize: "16px", color: "#666666" }}>
                  {t("display.text.text.homeFeedPromtP2")}
                </Typography>
              </>
            )}{" "}
            {value === 3 && (
              <>
                <Typography sx={{ fontSize: "16px", color: "#666666" }}>
                  {t("display.text.text.homeFeedPromtP4")}
                </Typography>
              </>
            )}
          </Stack>
        </Card>
      </Box>
    );
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={() => {
          if (mode !== "self") {
            handleClose();
          }
        }}
        sx={{ ".MuiPaper-root": { backgroundColor: "transparent" } }}
      >
        <Card id="welcome-promt" sx={{ width: "100%", p: 1 }}>
          <Stack spacing={1}>
            <Box>
              <Swiper
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                  disabledClass: "derek-button-disabled",
                }}
                modules={[Navigation]}
                className="mySwiper"
                slidesPerView={1}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
              >
                {Array(4)
                  .fill()
                  .map((_, item) => (
                    <SwiperSlide>
                      <IntroCard value={item} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Box>
          </Stack>
        </Card>
      </Dialog>
    </>
  );
};

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import CustomAvatar from "../../UI/CustomAvathar";
import { findOwner } from "../../utils/functions";
import { t } from "i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { DeleteEntryPromt } from "../atoms/DeleteEntryPromt";
import { usePostConfirmRelationRequestMutation } from "../../redux/slice/relationRequest";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const RelationRequestReceivedCard = ({
  base_relation,
  decider_name,
  decider_url,
  decision_date,
  fstr,
  lg_id,
  lg_id_name,
  lg_id_url,
  objKey,
  readURL,
  related_lg_id,
  req_decision,
  request_direction = "",
  requested_date,
  requestor_name,
  requestor_url,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const owner = findOwner(lg_id);
  const [open, setOpen] = useState(false);
  const [handleDecesision] = usePostConfirmRelationRequestMutation();
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const { userStatus } = useSelector((state) => state.config);

  //-----------------------Declare Functions Here-----------------------------//

  const handleAcceptRelation = () => {
    handleDecesision({
      lg_id: lg_id,
      related_lg_id: related_lg_id,
      base_relation: base_relation,
      req_decision: "Approved",
    });
  };
  const handleRejectRelation = () => {
    handleDecesision({
      lg_id: lg_id,
      related_lg_id: related_lg_id,
      base_relation: base_relation,
      req_decision: "Rejected",
    });
  };
  return (
    <>
      <Card
        sx={{
          borderStyle: "solid",
          borderColor: "border.main",
          borderWidth: "thin",
          p: 0.5,
        }}
      >
        <Stack spacing={1}>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              cursor: "pointer",
              gap: 0.5,
            }}
          >
            <Typography variant="caption">
              {t("display.text.text.requestedBy")}
            </Typography>
            <Typography variant="caption">{requestor_name}</Typography>
          </Box>
          <Divider></Divider>
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 0.5,
              width: "fit-content",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              component={"div"}
              onClick={() => navigate(`/profile/${owner?.lg_url}/about`)}
            >
              <Box sx={{ pt: 0.5 }}>
                <CustomAvatar
                  sx={{ height: "20px", width: "20px" }}
                  src={owner.readURL}
                />
              </Box>
              {`${owner.fname} ${owner.lname}`}
            </Typography>
            <Typography>
              {" "}
              {request_direction.toLowerCase() === "recieved"
                ? owner.owner_type === "Self"
                  ? `${t("display.text.text.areThe")} ${base_relation} ${t(
                      "display.text.text.of"
                    )}`
                  : `${t("display.text.text.isThe")} ${base_relation} ${t(
                      "display.text.text.of"
                    )}`
                : `${t("display.text.text.isThe")} ${base_relation} ${t(
                    "display.text.text.of"
                  )}`}
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              component={"div"}
              onClick={() =>
                navigate(`/profile/${lg_id_url.split("/")[4]}/about`)
              }
            >
              <Box sx={{ pt: 0.5 }}>
                <CustomAvatar
                  sx={{ height: "20px", width: "20px" }}
                  src={readURL}
                />
              </Box>
              <Box>{lg_id_name}</Box>
            </Typography>
          </Box>
          <Box>
            {req_decision === "Approved" && (
              <Typography variant="caption" sx={{ color: "green" }}>
                {t("display.text.text.approvedBy")} {decider_name}
              </Typography>
            )}
            {req_decision === "Rejected" && (
              <Typography variant="caption" sx={{ color: "red" }}>
                {t("display.text.text.rejectedBy")} {decider_name}
              </Typography>
            )}
            {req_decision === "Requested" && (
              <Stack
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
                direction={"row"}
                spacing={2}
              >
                <Button
                  onClick={handleRejectRelation}
                  sx={{ p: 0 }}
                  variant="contained"
                  color="secondary"
                  disabled={userStatus === "inactive"}
                >
                  {t("display.text.button.no")}
                </Button>
                <Button
                  onClick={() => setOpen(true)}
                  sx={{ p: 0 }}
                  variant="contained"
                  color="primary"
                  disabled={userStatus === "inactive"}
                >
                  {t("display.text.button.yes")}
                </Button>
              </Stack>
            )}
          </Box>
        </Stack>
      </Card>
      <DeleteEntryPromt
        open={open}
        close={handleClose}
        onCancel={handleClose}
        onDelete={handleAcceptRelation}
        message={t("display.text.text.confirmRelationRequestPromt")}
        title={t("display.text.text.areYouSure")}
        type="success"
      />
    </>
  );
};

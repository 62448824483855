import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "../../UI/CustomDialog";
import { resetCreateLg } from "../../redux/slice/createLg";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import { t } from "i18next";
import { useForm, Controller } from "react-hook-form";
import { deceasedDropdown, genderList } from "../../utils/ListItems";
import { HelperIcon } from "../atoms/HelperIcon";
import {
  personalDefaultValues,
  regularExpressions,
} from "../../utils/constants";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useEditLgAboutMutation,
  usePostLgAboutMutation,
} from "../../redux/slice/lgabout";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  capitalizeFirstLetter,
  chooseGenderFromValue,
  isSelf,
} from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { DeleteEntryPromt } from "../atoms/DeleteEntryPromt";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLazyCheckLgUrlQuery } from "../../redux/slice/checkLgUrl";

export const LgAbout = ({
  open,
  close = () => {},
  mode,
  first_name = null,
  last_name = null,
  nickname = null,
  gender = null,
  email_id = null,
  lg_url = null,
  deceased = null,
  short_desc = null,
  basic_add_details = null,
  base_relation,
  base_lg_id,
  user_owner_type,
  lg_id,
  onNext = () => {},
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const dispatch = useDispatch();
  const { relationship, relatedTo } = useSelector((state) => state.createLg);
  const { usrEmail } = useSelector((state) => state.authentication);
  const defaultValues = {
    title: null,
    first_name: mode === "edit" ? first_name : null,
    last_name: mode === "edit" ? last_name : null,
    nickname: mode === "edit" ? nickname : null,
    gender:
      mode === "edit"
        ? gender
        : mode === "self"
        ? null
        : capitalizeFirstLetter(chooseGenderFromValue(relationship)),
    email_id: mode === "self" ? usrEmail : mode === "edit" ? email_id : null,
    lg_url: mode === "edit" ? lg_url : null,
    deceased: mode === "self" ? "No" : mode === "edit" ? deceased : "No",
    short_desc: mode === "edit" ? short_desc : null,
    basic_add_details: mode === "edit" ? basic_add_details : null,
    base_relation:
      mode === "self"
        ? "Self"
        : mode === "new"
        ? relationship
        : mode === "edit"
        ? base_relation
        : null,
    base_lg_id:
      mode === "self"
        ? null
        : mode === "new"
        ? relatedTo.lg_id
        : mode === "edit"
        ? base_lg_id
        : null,
    basic_visib: "Public",
    url: "lgBasic",
    dob: null,
  };
  const { handleSubmit, control, formState, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const [postLgAbout, { isLoading }] = usePostLgAboutMutation();
  const [editLg, { isLoading: loading }] = useEditLgAboutMutation();
  const navigate = useNavigate();
  const [emailConfirmPromt, setEmailConfirmPromt] = useState(false);
  const [validLgUrl, setValidLgUrl] = useState(true);
  const [trigger, { isFetching }] = useLazyCheckLgUrlQuery();
  let timer = useRef();
  dayjs.extend(utc);

  //-----------------------Declare Functions Here-----------------------------//
  const isLgUrlAvailable = (value) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      if (value?.length >= 4) {
        trigger(value).then((response) => {
          if (response.data?.message === "url_uniq") {
            setValidLgUrl(true);
          } else {
            setValidLgUrl(false);
          }
        });
      }
    }, 1000);
  };

  const isDateMandatory = () => {
    if (mode === "self") return true;
    else if (
      mode === "new" &&
      (relationship.toLowerCase() === "son" ||
        relationship.toLowerCase() === "daughter" ||
        relationship.toLowerCase() === "husband" ||
        relationship.toLowerCase() === "wife")
    ) {
      return true;
    }
    return false;
  };
  const formatData = (data) => {
    if (
      mode === "edit" &&
      formState.dirtyFields?.email_id &&
      getValues("email_id") !== "" &&
      !isSelf(lg_id)
    ) {
      setEmailConfirmPromt(data);
    } else {
      if (mode === "edit") {
        editLg({ ...data, lg_id: lg_id })
          .then(() => close())
          .catch(() => close());
      } else {
        postLgAbout(data)
          .then((response) => {
            editLg({
              ...personalDefaultValues,
              lg_id: response.data.lg_id,
              url: "lgPersonal",
              dob: data.dob,
            });
            navigate(`/profile/${data.lg_url}/about`);
            onNext(data.lg_url);
          })
          .catch(() => close());
      }
    }
  };
  const handleClosePromt = () => {
    setEmailConfirmPromt(false);
  };
  const onConfirmEmailPromt = () => {
    editLg({ ...emailConfirmPromt, lg_id: lg_id })
      .then(() => close())
      .catch(() => close());
  };
  let dummy = {
    isValid: formState.isValid,
    isDirty: formState.isDirty,
  };
  return (
    <>
      <CustomDialog
        open={open}
        iconPlacement={mode === "self" ? "no" : "right"}
        close={() =>
          mode === "self"
            ? null
            : mode === "new"
            ? dispatch(resetCreateLg())
            : close()
        }
        title={
          <Typography
            variant="h6"
            sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
            align="center"
          >
            {t(
              mode === "new"
                ? "display.text.text.newLifograf"
                : mode === "self"
                ? "display.text.text.selfLifograf"
                : "display.text.text.editLifograf"
            )}
          </Typography>
        }
        action={
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <LoadingButton
              variant="contained"
              loading={isLoading || loading}
              disabled={!(formState.isDirty && formState.isValid && validLgUrl)}
              onClick={handleSubmit((data) => formatData(data))}
            >
              {t("display.text.button.save")}
            </LoadingButton>
          </Box>
        }
      >
        <Card sx={{ width: "100%", p: 1 }}>
          <Stack spacing={2}>
            <Controller
              name={"first_name"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("display.text.inputField.firstName")}
                  type={"text"}
                  inputProps={{
                    maxLength: 21,
                  }}
                  required={true}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  sx={{ width: "100%" }}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("display.text.error.fieldIsRequired", {
                    param1: t("display.text.inputField.firstName"),
                  }),
                },
                maxLength: {
                  value: 20,
                  message: t("display.text.error.userNameValidationErr"),
                },
              }}
            />
            <Controller
              name={"last_name"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("display.text.inputField.lastName")}
                  type={"text"}
                  inputProps={{
                    maxLength: 21,
                  }}
                  required={true}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  sx={{ width: "100%" }}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("display.text.error.fieldIsRequired", {
                    param1: t("display.text.inputField.lastName"),
                  }),
                },
                maxLength: {
                  value: 20,
                  message: t("display.text.error.userNameValidationErr"),
                },
              }}
            />

            <Controller
              name={"nickname"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("display.text.inputField.nickname")}
                  type={"text"}
                  inputProps={{
                    maxLength: 21,
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  sx={{ width: "100%" }}
                />
              )}
              rules={{
                maxLength: {
                  value: 20,
                  message: t("display.text.error.userNameValidationErr"),
                },
              }}
            />
            {(mode === "self" || mode === "new") && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name={"dob"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <DatePicker
                      {...field}
                      // onChange={() => {}}
                      onAccept={(e) => {
                        field.onChange(dayjs(e).utc(true).format());
                      }}
                      label={`${t("display.text.inputField.dob")}${
                        isDateMandatory() ? "*" : ""
                      }`}
                      maxDate={dayjs()}
                      inputFormat="dd/mm/yyyy"
                      value={field.value ? dayjs(field.value) : null}
                      renderInput={(params) => (
                        <TextField
                          sx={{ width: "100%" }}
                          {...params}
                          error={fieldState.error ? true : false}
                          required={isDateMandatory()}
                          helperText={
                            fieldState.error
                              ? fieldState.error.message
                              : t("display.text.texts.useApproxDate")
                          }
                        />
                      )}
                    />
                  )}
                  rules={{
                    required: {
                      value: isDateMandatory(),
                      message: t("display.text.error.fieldIsRequired", {
                        param1: t("display.text.inputField.dob"),
                      }),
                    },
                    validate: (value) =>
                      new Date(value) <= new Date() ||
                      t("display.text.error.dateError"),
                  }}
                />
              </LocalizationProvider>
            )}
            {mode === "self" && (
              <Controller
                name={"gender"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    select={true}
                    label={t("display.text.inputField.gender")}
                    required={true}
                    sx={{ width: "100%" }}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    {genderList.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("display.text.error.fieldIsRequired", {
                      param1: t("display.text.inputField.gender"),
                    }),
                  },
                }}
              />
            )}
            {mode !== "self" && (
              <Controller
                name={"email_id"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("display.text.inputField.email")}
                    type={"email"}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    sx={{ width: "100%" }}
                  />
                )}
                rules={{
                  pattern: {
                    value: regularExpressions.email,
                    message: t("display.text.error.emailValidationErr"),
                  },
                }}
              />
            )}

            <Controller
              name={"lg_url"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  onChange={(e) => {
                    isLgUrlAvailable(e.target.value);
                    field.onChange(e.target.value);
                  }}
                  label={t("display.text.inputField.lifografHandle")}
                  type={"text"}
                  required={true}
                  placeholder="e.g. robert, raja1 , cool_bob"
                  disabled={mode === "edit" ? true : false}
                  error={fieldState.error ? true : !validLgUrl ? true : false}
                  inputProps={{
                    maxLength: 15,
                  }}
                  helperText={
                    fieldState.error
                      ? fieldState.error.message
                      : !validLgUrl
                      ? t("display.text.error.lgUrl")
                      : null
                  }
                  InputProps={{
                    endAdornment: isFetching ? (
                      <CircularProgress />
                    ) : (
                      <HelperIcon
                        helperText={
                          <Typography variant="body1">
                            {t("display.text.helperText.lgUrl")}
                          </Typography>
                        }
                      />
                    ),
                  }}
                  sx={{ width: "100%" }}
                />
              )}
              rules={{
                validate: {
                  required: (value) =>
                    Boolean(value) === true ||
                    t("display.text.error.fieldIsRequired", {
                      param1: t("display.text.inputField.lifografHandle"),
                    }),
                  maxLength: (value) =>
                    value.length < 15 ||
                    t("display.text.error.maxCharacters", {
                      param1: 100,
                    }),
                  minLength: (value) =>
                    value.length >= 4 ||
                    t("display.text.error.minimumCharacterError"),
                  pattern: (value) =>
                    value.match(regularExpressions.lgUrl) !== null ||
                    t("display.text.error.lgUrlType"),
                },
              }}
            />
            {mode !== "self" &&
              mode === "edit" &&
              user_owner_type !== "Self" && (
                <Controller
                  name={"deceased"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      select={true}
                      label={t("display.text.inputField.alive")}
                      required={true}
                      sx={{ width: "100%" }}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                    >
                      {deceasedDropdown.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: t("display.text.error.fieldIsRequired", {
                        param1: t("display.text.inputField.alive"),
                      }),
                    },
                  }}
                />
              )}

            <Controller
              name={"short_desc"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("display.text.inputField.shortDescription")}
                  type={"text"}
                  inputProps={{
                    maxLength: 101,
                  }}
                  required={true}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    endAdornment: (
                      <HelperIcon
                        helperText={
                          <Typography variant="body1">
                            {t("display.text.helperText.shortDescription")}
                          </Typography>
                        }
                      />
                    ),
                  }}
                  sx={{ width: "100%" }}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("display.text.error.fieldIsRequired", {
                    param1: t("display.text.inputField.shortDescription"),
                  }),
                },
                maxLength: {
                  value: 100,
                  message: t("display.text.error.maxCharacters", {
                    param1: 100,
                  }),
                },
              }}
            />

            <Controller
              name={"basic_add_details"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("display.text.inputField.introduction")}
                  type={"text"}
                  inputProps={{
                    maxLength: 1001,
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    endAdornment: (
                      <HelperIcon
                        helperText={
                          <Typography variant="body1">
                            {t("display.text.helperText.introduction")}
                          </Typography>
                        }
                      />
                    ),
                  }}
                  sx={{ width: "100%" }}
                />
              )}
              rules={{
                maxLength: {
                  value: 1000,
                  message: t("display.text.error.maxCharacters", {
                    param1: 1000,
                  }),
                },
              }}
            />
          </Stack>
        </Card>
      </CustomDialog>
      <DeleteEntryPromt
        open={Boolean(emailConfirmPromt)}
        close={handleClosePromt}
        onCancel={handleClosePromt}
        onDelete={onConfirmEmailPromt}
        title={t("display.text.text.areYouSure")}
        message={
          <Typography
            sx={{
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
            }}
          >
            {t("display.text.text.confirmEmailPromt")}
          </Typography>
        }
        type={"success"}
      />
    </>
  );
};
